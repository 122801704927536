import * as z from 'zod'
import React from 'react'
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Paper, Typography
} from '@material-ui/core'
import { Form, FormTextField } from '../../controls/Form'
import { useTranslation } from 'react-i18next'
import { SessionPin } from '../../../api/auth/types'
import { useForm } from '../../controls/Form'
import { SubmitHandler } from 'react-hook-form'
import { FormButtons } from '../../layout/FormButtons'

const FormType = z.object({
  pin: SessionPin
})
type FormType = z.infer<typeof FormType>

type Props = {
  readonly open: boolean
  readonly onComplete: (pin: SessionPin | undefined) => void
}

export const PinInputDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const form = useForm({
    resolver: FormType,
    defaultValues: {}
  })

  const handleSubmit: SubmitHandler<FormType> = (data) => {
    form.reset()
    props.onComplete(data.pin)
  }

  const handleCancel: () => void = () => {
    form.reset()
    props.onComplete(undefined)
  }

  return (
    <Dialog open={props.open}>
      <Paper>
        <DialogTitle disableTypography>
          <Typography variant='h4' color='primary'>
            {t('dialog.pin.title')}
          </Typography>
        </DialogTitle>
        <Form f={form} onSubmit={handleSubmit}>
          <DialogContent>
            <Typography>{t('dialog.pin.message')}</Typography>
            <FormTextField name={form.names.pin} autoComplete='off' type='number' />
          </DialogContent>
          <DialogActions>
            <FormButtons
              form={form}
              noTextKey='dialog.pin.btn-cancel'
              yesTextKey='dialog.pin.btn-submit'
              onCancel={handleCancel}
            />
          </DialogActions>
        </Form>
      </Paper>
    </Dialog>
  )
}

