import * as React from 'react'
import { menuLink, MenuLink } from './types'
import { routes } from '../../../constants/routes'
import HeaderMenu from './HeaderMenu'

const menuLinks: ReadonlyArray<MenuLink> = [menuLink(routes.Home, (t) => t('header.menu.home'))]

const TechnicianHeader: React.FC = () => {
  return <HeaderMenu menuItems={menuLinks} />
}

export default TechnicianHeader
