import * as React from 'react'
import { Typography } from '@material-ui/core'
import { routes } from '../../../constants/routes'
import { useContext } from 'react'
import { MessageType, NotificationContext } from '../../context/NotificationContext'
import WifiTetheringIcon from '@material-ui/icons/WifiTethering'
import PortableWifiOffIcon from '@material-ui/icons/PortableWifiOff'
import { menuLink, MenuLink } from './types'
import HeaderMenu from './HeaderMenu'

const menuLinks: ReadonlyArray<MenuLink> = [
  menuLink(routes.Home, (t) => t('header.menu.home'), [MessageType.enum.NewLedger, MessageType.enum.NewTodo, MessageType.enum.TodoDone]),
  menuLink(routes.Reports, (t) => t('header.menu.reports')),
  menuLink(routes.Activity, (t) => t('header.menu.activity'))
]

const OperatorHeader: React.FC = () => {
  const { active } = useContext(NotificationContext)

  return (
    <HeaderMenu menuItems={menuLinks}>
      <Typography className="notification-status" variant="body1">
        {active ? (
          <WifiTetheringIcon color="primary" fontSize="large" />
        ) : (
          <PortableWifiOffIcon color="secondary" fontSize="large" />
        )}
      </Typography>
    </HeaderMenu>
  )
}

export default OperatorHeader
