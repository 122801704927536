import * as React from 'react'
import {
  Avatar,
  Box,
  CardActions,
  CardContent,
  CardHeader, Dialog,
  Typography
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import {FallbackProps} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import Button from '../controls/Button'
import { ApiError } from '../../api/util/base-api'

type ErrorInfo = {
  readonly componentStack: string
}

export const logError: (error: Error, info: ErrorInfo) => void = (error, info) => {
  console.error(`got [${error.name}] ${error.message}, stack info: ${info.componentStack}`)
}

export const errorRenderer = (props: FallbackProps): React.ReactElement => {
  if (props.error.name === 'ApiError') {
    const apiError = props.error as ApiError
    switch (apiError.errorCode) {
      default: {
        return (
          <Dialog open={true}>
            <ErrorPage {...props} />
          </Dialog>
        )
      }
    }
  } else {
    return (
      <Dialog open={true}>
        <ErrorPage {...props} />
      </Dialog>
    )
  }
}

const ErrorPage: React.ComponentType<FallbackProps> = ({error, resetErrorBoundary}) => {
  const {t} = useTranslation()

  return (
    <React.Fragment>
      <CardHeader
        avatar={<Avatar><ErrorIcon /></Avatar>}
        title={t('dialog.error.title')}
        titleTypographyProps={{ variant: 'h5' }}
      />
      <CardContent>
        <Box>
          <Typography>
            {t('dialog.error.message', {error: error.message})}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Button onClick={resetErrorBoundary} variant={'contained'} color={'primary'} fullWidth>
          {t('dialog.error.btn-reset')}
        </Button>
      </CardActions>
    </React.Fragment>
  )
}
