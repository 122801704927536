import * as z from 'zod'
import { GrainType } from '../../types'
import { GrainTargetCondition } from '../types'

export const GrainSample = z.object({
  measured: z.boolean(),
  moisture: z.number(),
  temperature: z.number().nullish(),
  natureKgPerLitre: z.number().nullish()
})
export type GrainSample = z.infer<typeof GrainSample>

export const TemperatureSample = z.object({
  measured: z.boolean(),
  top: z.number(),
  middle: z.number(),
  bottom: z.number(),
  burner: z.number()
})
export type TemperatureSample = z.infer<typeof TemperatureSample>

const SettingsSample = z.object({
  burnerTemp: z.number().nullish(),
  dropInterval: z.string().nullish(), // duration
  dropDuration: z.string().nullish(), // duration
  lowerBoundary: z.number().nullish(),
  upperBoundary: z.number().nullish()
})

export const QualityClass = z.enum(['Wet', 'Good', 'OverDried'])

const ProductivitySample = z.object({
  tons: z.number(),
  tonsPerHour: z.number(),
  quality: QualityClass
})

const DryerModeType = z.enum([
  'Undefined',
  'Stop',
  'Filling',
  'Recycling',
  'Cooling',
  'Drying',
  'Unloading',
  'Manual',
  'Shutdown'
])

// @JsonTypeName for DataSample's
export const DryerOperationType = z.enum([
  'filling',
  'recycling',
  'cooling',
  'idle',
  'drying',
  'unloading',
  'unknown'
])
export type DryerOperationType = z.infer<typeof DryerOperationType>

const BaseDataSample = z.object({
  operation: DryerOperationType,
  timestamp: z.string(),  // instant
  settings: SettingsSample,
})

const IdleDataSample = BaseDataSample.extend({
  operation: z.literal(DryerOperationType.enum.idle),
  mode: DryerModeType
})

export const FillingData = z.object({
  wet: GrainSample.nullish(),
  temperature: TemperatureSample.nullish()
})

const FillingDataSample = BaseDataSample.merge(FillingData).extend({
  operation: z.literal(DryerOperationType.enum.filling),
})

export const UnloadingDataSample = BaseDataSample.extend({
  operation: z.literal(DryerOperationType.enum.unloading),
  drop: z.number(),
  dropMeasured: z.boolean(),
})

export const DryingData = z.object({
  drop: z.number(),
  dropMeasured: z.boolean(),
  grainType: GrainType,
  dry: GrainSample.nullish(),
  temperature: TemperatureSample.nullish(),
  productivity: ProductivitySample.nullish()
})
export type DryingData = z.infer<typeof DryingData>

export const CoolingDataSample = BaseDataSample.merge(DryingData).merge(FillingData).extend({
  operation: z.literal(DryerOperationType.enum.cooling),
  dryingMode: GrainTargetCondition,
})

const RecyclingDataSample = BaseDataSample.merge(DryingData).extend({
  operation: z.literal(DryerOperationType.enum.recycling),
})

export const DryingDataSample = BaseDataSample.merge(DryingData).merge(FillingData).extend({
  operation: z.literal(DryerOperationType.enum.drying),
  dryingMode: GrainTargetCondition,
})
export type DryingDataSample = z.infer<typeof DryingDataSample>

export const UnknownDataSample = BaseDataSample.merge(DryingData).merge(FillingData).extend({
  operation: z.literal(DryerOperationType.enum.unknown),
  dryingMode: GrainTargetCondition,
})
export type UnknownDataSample = z.infer<typeof UnknownDataSample>

export const DataSample = z.union([
  IdleDataSample, FillingDataSample, RecyclingDataSample, CoolingDataSample, DryingDataSample, UnloadingDataSample, UnknownDataSample
])
export type DataSample = z.infer<typeof DataSample>

export const StatisticSample = z.object({
  timestamp: z.string(), // date
  tooWetTons: z.number(),
  goodTons: z.number(),
  overDriedTons: z.number()
})
export type StatisticSample = z.infer<typeof StatisticSample>

const ValueStatistic = z.object({
  minV: z.number(),
  avgV: z.number(),
  maxV: z.number()
})

export const DryingStatistic = z.object({
  data: z.array(StatisticSample),
  moisture: ValueStatistic,
  avgDryingSpeedTonsPerHr: z.number()
})
export type DryingStatistic = z.infer<typeof DryingStatistic>

export const MeasurementsResponse = z.object({
  data: z.array(DataSample)
})
export type MeasurementsResponse = z.infer<typeof MeasurementsResponse>
