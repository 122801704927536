import * as React from 'react'
import * as z from 'zod'
import { DryerId, GrainType } from '../../../api/types'
import { useTranslation } from 'react-i18next'
import { Form, FormTextField, FormSelectField, useForm } from '../../controls/Form'
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Switch,
  Grid, Paper, Typography, AccordionSummary, Accordion, AccordionDetails, CircularProgress
} from '@material-ui/core'
import { SubmitHandler, Controller, useWatch } from 'react-hook-form'
import { NewMoistureMeasurement } from '../../context/NotificationContext'
import { useMeasurementSampleQuery } from '../../../api/dryers/measurements'
import { parseISO } from 'date-fns'
import TextField from '../../controls/TextField'
import SelectDryerInput from '../../controls/SelectDryerInput'
import { useAckMoistureMutation } from '../../../api/sensors/perten'
import { PertenProcessSampleRequest } from '../../../api/sensors/perten/types'
import { FormButtons } from '../../layout/FormButtons'
import { dateTimeShortFormat, useDateFormatter } from '../../../utils/date-utils'
import { getDropData, getTemperatures } from '../../data-sample-utils'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'

type Props = {
  readonly sample: NewMoistureMeasurement
  readonly onComplete: React.Dispatch<OptionalType<DryerId>>
}

const FormData = z.object({
  dryerId: DryerId,
  grainType: GrainType.refine((gt) => gt !== GrainType.enum.UNKNOWN, { message: 'Correct value required'}),
  isDry: z.boolean(),
  comment: z.string().optional()
})
type FormData = z.infer<typeof FormData>

const form2request: (data: FormData) => PertenProcessSampleRequest = (data) => ({
  dryerId: data.dryerId,
  grainType: data.grainType,
  isDry: data.isDry,
  comment: data.comment
})

const inferGrainType: (sample: NewMoistureMeasurement) => GrainType =
  (sample) =>
    GrainType.options.find((o) => sample.grainProfile.toUpperCase().startsWith(o)) ?? GrainType.enum.UNKNOWN

export const ConfirmMoistureDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const addNew = useAckMoistureMutation(props.sample.resultId, props.sample.deviceId)
  const inferredGrainType = inferGrainType(props.sample)
  const dtFormatter = useDateFormatter(dateTimeShortFormat)
  const form = useForm({
    resolver: FormData,
    defaultValues: {
      grainType: inferredGrainType,
      isDry: true
    }
  })
  const dryerId = useWatch({ control: form.control, name: 'dryerId' })
  const fetch = useMeasurementSampleQuery(dryerId, parseISO(props.sample.receivedAt), {
    enabled: dryerId !== undefined
  })

  const handleSubmit: SubmitHandler<FormData> = (data) => {
      addNew.mutate(form2request(data), {
        onSettled: (_, error) => props.onComplete(error ? undefined : data.dryerId)
      })
  }

  const grainTypeOptions = React.useMemo(
    () =>
      GrainType.options.map((o) => ({
        label: t(`types.grain.${o}`),
        value: o
      })),
    [t]
  )

  const readonlyNumberField: (value: number | undefined, labelSuffix: string) => React.ReactNode = (value, labelSuffix) =>
    <TextField
      label={t(`dialog.moisture.add-new.form.${labelSuffix}`)}
      value={value}
      inputProps={{ readOnly: true }}
      type={'number'}
      aria-readonly={true}
    />

  const readonlyTextField: (value: string | undefined, labelSuffix: string) => React.ReactNode = (value, labelSuffix) =>
    <TextField
      label={t(`dialog.moisture.add-new.form.${labelSuffix}`)}
      value={value}
      inputProps={{ readOnly: true }}
      aria-readonly={true}
    />

  return (
    <Dialog open={true}>
      <Paper>
        <DialogTitle disableTypography>
          <Typography variant='h5'>
            {t('dialog.moisture.add-new.title')}
          </Typography>
        </DialogTitle>
        <Form f={form} onSubmit={handleSubmit}>
          <DialogContent>
            <SelectDryerInput field={form.names.dryerId} withDefault/>
            <FormSelectField
              name={form.names.grainType}
              label={t('dialog.moisture.add-new.form.grain-type')}
              options={grainTypeOptions}
            />
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>{t('dialog.moisture.add-new.form.wet')}</Grid>
              <Grid item>
                <Controller
                  name='isDry'
                  control={form.control}
                  render={({ field: { value, ...field } }) => (
                    <Switch color="primary" checked={value} inputProps={{ 'aria-label': 'primary checkbox' }} {...field} />
                  )}
                />
              </Grid>
              <Grid item>{t('dialog.moisture.add-new.form.dry')}</Grid>
            </Grid>
            {readonlyTextField(dtFormatter(parseISO(props.sample.receivedAt)), 'timestamp')}
            {inferredGrainType === GrainType.enum.UNKNOWN &&
              readonlyTextField(props.sample.grainProfile, 'grain-profile')
            }
            {readonlyNumberField(props.sample.moisture, 'moisture')}
            {readonlyNumberField(props.sample.temperature, 'seeds-temp')}
            {props.sample.natureKgPerL && readonlyNumberField(props.sample.natureKgPerL, 'seeds-nature')}
            <FormTextField name={form.names.comment} label={t('dialog.moisture.add-new.form.comment')} />
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowDropDownCircleIcon fontSize='large' />}
                aria-controls='panel-content'
              >
                <Typography>
                  {t('dialog.moisture.add-new.form.accordion-summary')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container wrap='nowrap' direction='column'>
                  {inferredGrainType !== GrainType.enum.UNKNOWN &&
                    readonlyTextField(props.sample.grainProfile, 'grain-profile')
                  }
                  <Grid item>
                    {readonlyTextField(props.sample.originalTimestamp, 'original-timestamp')}
                  </Grid>
                  <Grid item>
                    {readonlyTextField(props.sample.address, 'address')}
                  </Grid>
                  <Grid item>
                    {readonlyNumberField(props.sample.resultId, 'result-id')}
                  </Grid>
                  {fetch.isLoading && <CircularProgress />}
                  {fetch.isSuccess && fetch.data &&
                    <React.Fragment>
                      <Grid item>
                        {readonlyNumberField(getDropData(fetch.data)?.drop, 'drops-score')}
                      </Grid>
                      <Grid>
                        {readonlyTextField(getTemperatures(fetch.data)?.burner?.toFixed(1), 'actual-burner-temp')}
                      </Grid>
                    </React.Fragment>
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions>
            <FormButtons
              form={form}
              noTextKey='dialog.moisture.add-new.close'
              yesTextKey='dialog.moisture.add-new.submit'
              onCancel={() => props.onComplete(undefined)}
            />
          </DialogActions>
        </Form>
      </Paper>
    </Dialog>
  )
}

