import * as React from 'react'
import { RouteProps, Switch, Route, Redirect } from 'react-router-dom'
import { SessionType, WorkModeContext } from './components/context/WorkModeContext'
import MainLayout from './components/layout/MainLayout'
import { routes } from './constants/routes'
import './styles/fonts.css'

const Login = React.lazy(() => import('./components/pages/Login'))
const SetPassword = React.lazy(() => import('./components/pages/SetPassword'))
const OperatorHome = React.lazy(() => import('./components/pages/OperatorHome'))
const TechHome = React.lazy(() => import('./components/pages/TechHome'))
const Dryer = React.lazy(() => import('./components/pages/Dryer'))
const DryerConfiguration = React.lazy(() => import('./components/pages/DryerConfiguration'))
const Activity = React.lazy(() => import('./components/pages/Activity'))
const Reports = React.lazy(() => import('./components/pages/Reports'))
const SensorConfiguration = React.lazy(() => import('./components/pages/SensorConfiguration'))

type RouteConfig = {
  readonly routes: ReadonlyArray<RouteProps>
  readonly redirect: string
}

const operatorRoutes: ReadonlyArray<RouteProps> = [
  { path: routes.Dryer, component: Dryer },
  { path: routes.Activity, component: Activity },
  { path: routes.Reports, component: Reports },
  { path: routes.Login, component: Login },
  { path: routes.ChangePassword, component: SetPassword },
  { path: routes.Home, component: OperatorHome }
]

const technicianRoutes: ReadonlyArray<RouteProps> = [
  { path: routes.Dryer, component: DryerConfiguration },
  { path: routes.Sensors, component: SensorConfiguration },
  { path: routes.ChangePassword, component: SetPassword },
  { path: routes.Home, component: TechHome }
]

const publicRoutes: ReadonlyArray<RouteProps> = [
  { path: routes.ChangePassword, component: SetPassword },
  { path: routes.Login, component: Login }
]

const operatorRouterConfig = (): RouteConfig => ({
  routes: operatorRoutes,
  redirect: routes.Home
})

const technicianRouterConfig = (): RouteConfig => ({
  routes: technicianRoutes,
  redirect: routes.Home
})

const publicRouterConfig = (): RouteConfig => ({
  routes: publicRoutes,
  redirect: routes.Login
})

const getRouterConfigForRole = (sessionType: SessionType): RouteConfig => {
  switch (sessionType) {
    case SessionType.enum.workshift:
      return operatorRouterConfig()
    case SessionType.enum.technician:
      return technicianRouterConfig()
    default:
      return publicRouterConfig()
  }
}

const App: React.FC = () => {
  const { sessionType } = React.useContext(WorkModeContext)

  const { routes, redirect } = React.useMemo(
    () => getRouterConfigForRole(sessionType),
    [sessionType]
  )

  return (
    <MainLayout workMode={sessionType}>
      <Switch>
        {routes.map((r) => (
          // TODO: Json.stringify is not the best option here
          <Route key={JSON.stringify(r.path)} {...r} />
        ))}
        <Redirect to={redirect} />
      </Switch>
    </MainLayout>
  )
}

export default App
