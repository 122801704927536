import * as z from 'zod'
import { apiBaseUrl } from '../../constants/configuration'
import { UseMutationOptions, UseMutationResult } from '../util/types'
import {
  LoginProvider,
  LoginRequest,
  LoginResponse,
  SetPasswordRequest,
  LogoutResponse
} from './types'
import { useApiMutation, useReceiveOnly, useSendReceive } from '../util/hooks'

const authUrl = `${apiBaseUrl}/auth`
const authProviderUrl = (loginProvider: LoginProvider): string => `${authUrl}/login/${loginProvider}`

export const getUserKey = 'getUserKey'

const SessionResponse = z.string().nonempty()
type SessionResponse = z.infer<typeof SessionResponse>

export const useLoginUser = (): UseMutationResult<LoginResponse, LoginRequest> =>
  useApiMutation(useSendReceive('post', authProviderUrl('native'), LoginRequest, LoginResponse))

export const useSetUserPassword = (): UseMutationResult<LoginResponse, SetPasswordRequest> =>
  useApiMutation(useSendReceive('post', `${authUrl}/provision`, SetPasswordRequest, LoginResponse))

export const useLogoutUser = (): UseMutationResult<LogoutResponse, void> =>
  useApiMutation(useReceiveOnly('post', `${authUrl}/logout`, LogoutResponse))

export const useCheckSession = (
  opts?: UseMutationOptions<SessionResponse, void>
): UseMutationResult<SessionResponse, void> =>
  useApiMutation(useReceiveOnly('post', `${authUrl}/session`, SessionResponse), {...opts})
