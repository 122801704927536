import { apiBaseUrl } from '../../../constants/configuration'
import {
  PertenProcessSampleRequest,
  PertenResultId
} from './types'
import { UseMutationOptions, UseMutationResult } from '../../util/types'
import { useApiMutation, useSendOnly } from '../../util/hooks'
import { QueryKey } from 'react-query'
import { SensorDeviceLocalId } from '../../types'

const moistureQueryKey: QueryKey = 'moisture'
const fetchKey: (id: PertenResultId) => QueryKey = (id) => [moistureQueryKey, id]

const pertenApiUrl = `${apiBaseUrl}/perten`
const sampleUrl: (sampleId: PertenResultId, luid: SensorDeviceLocalId) => string = (sampleId, luid) => `${pertenApiUrl}/${luid}/samples/${sampleId}`

/*
export const useUnprocessedMoistureQuery = (): UseQueryResult<PertenSampleList> =>
  useApiQuery(moistureQueryKey, useReceiveOnly('get', pertenApiUrl, PertenSampleList))

export const useMoistureSampleQuery = (sampleId: PertenResultId, deviceLuid: SensorDeviceLocalId): UseQueryResult<PertenSample> =>
  useApiQuery(fetchKey(sampleId), useReceiveOnly('get', sampleUrl(sampleId, deviceLuid), PertenSample))
 */

export const useAckMoistureMutation = (sampleId: PertenResultId, deviceLuid: SensorDeviceLocalId, opts?: UseMutationOptions<void, PertenProcessSampleRequest>): UseMutationResult<void, PertenProcessSampleRequest> =>
  useApiMutation(useSendOnly('post', sampleUrl(sampleId, deviceLuid), PertenProcessSampleRequest), {
    invalidateQueries: [moistureQueryKey, fetchKey(sampleId)],
    ...opts
  })

export const useRemoveMoistureMutation = (sampleId: PertenResultId, deviceLuid: SensorDeviceLocalId, opts?: UseMutationOptions<void, void>): UseMutationResult<void, void> =>
  useApiMutation(useSendOnly('delete', sampleUrl(sampleId, deviceLuid), undefined), {
    invalidateQueries: [moistureQueryKey, fetchKey(sampleId)],
    ...opts
  })
