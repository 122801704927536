import {
  DataSample,
  DryingData,
  GrainSample,
  UnloadingDataSample, CoolingDataSample, FillingData, TemperatureSample
} from '../api/dryers/measurements/types'
import { mayBeType } from '../utils/zod-utils'
import { flatMap, optional } from '../utils/function-utils'
import { Optional } from '@amcharts/amcharts4/core'

export type DropData = {
  readonly drop: number,
  readonly measured: boolean
}

export function getDropData(data: DataSample): Optional<DropData> {
  return flatMap(
    mayBeType(data, DryingData) ??
    mayBeType(data, CoolingDataSample) ??
    mayBeType(data, UnloadingDataSample),
    (v) => ({ drop: v.drop, measured: v.dropMeasured })
  )
}

export function getDryingData(data: DataSample): Optional<DryingData> {
  return mayBeType(data, DryingData)
}

export function getWetGrain(data: DataSample): Optional<GrainSample> {
  return flatMap(mayBeType(data, FillingData), (v) => optional(v.wet))
}

export function getTemperatures(data: DataSample): Optional<TemperatureSample> {
  return flatMap(mayBeType(data, DryingData), (d) => optional(d.temperature)) ??
    flatMap(mayBeType(data, FillingData), (d) => optional(d.temperature))
}
