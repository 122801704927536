import * as z from 'zod'
import { DryerId, GrainType, SensorDeviceId } from '../types'

export const GrainTargetCondition = z.enum(['Undefined', 'UpTo6', 'UpTo12', 'MoreThan12'])

export const ValueTrend = z.enum(['FastDown', 'Down', 'Stable', 'Up', 'FastUp'])
export type ValueTrend = z.infer<typeof ValueTrend>

export const TrendedValue = z.object({
  value: z.number(),
  trend: ValueTrend
})
export type TrendedValue = z.infer<typeof TrendedValue>

export const DryingZoneCondition = z.object({
  topTemperature: TrendedValue,
  middleTemperature: TrendedValue,
  bottomTemperature: TrendedValue,
  burnerTemperature: TrendedValue,
  setBurnerTemperature: TrendedValue,
})
export type DryingZoneCondition = z.infer<typeof DryingZoneCondition>

export const GrainCondition = z.object({
  timestamp: z.string(),
  moisture: TrendedValue,
  temperature: TrendedValue,
  natureKgPerLitre: TrendedValue.nullish()
})
export type GrainCondition = z.infer<typeof GrainCondition>

// @JsonTypeName for DryerMode's
export const DryerModeTypeName = z.enum(['unknown', 'stopped', 'filling', 'recycling', 'cooling', 'drying', 'unloading', 'manual', 'shutdown'])

const BaseDryerMode = z.object({
  mode: DryerModeTypeName
})

export const DryerSetupInfo = z.object({
  currentGrainType: GrainType,
  currentDryingMode: GrainTargetCondition,
  lowerMoistureBoundary: z.number().nullish(),
  upperMoistureBoundary: z.number().nullish(),
})
export type DryerSetupInfo = z.infer<typeof DryerSetupInfo>

export const UnknownMode = DryerSetupInfo.extend({
  mode: z.literal(DryerModeTypeName.enum.unknown),
  drySeeds: GrainCondition.nullish(),
  wetSeeds: GrainCondition.nullish(),
})
export type UnknownMode = z.infer<typeof UnknownMode>

const StopMode = BaseDryerMode.extend({
  mode: z.literal(DryerModeTypeName.enum.stopped),
})

export const FillingMode = BaseDryerMode.merge(DryerSetupInfo).extend({
  mode: z.literal(DryerModeTypeName.enum.filling),
})

export const RecyclingMode = BaseDryerMode.merge(DryerSetupInfo).extend({
  mode: z.literal(DryerModeTypeName.enum.recycling),
  drying: DryingZoneCondition.nullish(),
  drySeeds: GrainCondition.nullish()
})

export const CoolingMode = BaseDryerMode.merge(DryerSetupInfo).extend({
  mode: z.literal(DryerModeTypeName.enum.cooling),
  drySeeds: GrainCondition.nullish()
})

export const DryingMode = BaseDryerMode.merge(DryerSetupInfo).extend({
  mode: z.literal(DryerModeTypeName.enum.drying),
  drying: DryingZoneCondition.nullish(),
  drySeeds: GrainCondition.nullish(),
  wetSeeds: GrainCondition.nullish(),
  dryingTonnPerHour: z.number().nullish()
})
export type DryingMode = z.infer<typeof DryingMode>

const UnloadingMode = BaseDryerMode.extend({
  mode: z.literal(DryerModeTypeName.enum.unloading),
})

const ManualMode = BaseDryerMode.extend({
  mode: z.literal(DryerModeTypeName.enum.manual),
})

const ShuttingDownMode = BaseDryerMode.extend({
  mode: z.literal(DryerModeTypeName.enum.shutdown),
})

const DryerMode = z.union([UnknownMode, StopMode, FillingMode, RecyclingMode, CoolingMode, DryingMode, UnloadingMode, ManualMode, ShuttingDownMode])
export type DryerMode = z.infer<typeof DryerMode>

const HopperInfoObject = z.object({
  volumeInLiters: z.number(),
  loadingTimeInSeconds: z.number() // duration
})

export const DryerObject = z.object({
  id: DryerId,
  name: z.string(),
  sensorDevices: z.array(SensorDeviceId),
  allowManual: z.boolean(),
  hopper: HopperInfoObject.nullish(),
  mode: DryerMode.nullish()
})
export type DryerObject = z.infer<typeof DryerObject>

export const DryerQueryResponse = z.array(DryerObject)
export type DryerQueryResponse = z.infer<typeof DryerQueryResponse>

export const ActivationRequest = z.object({
  deviceIds: z.array(SensorDeviceId),
  manualAllowed: z.boolean()
})
export type ActivationRequest = z.infer<typeof ActivationRequest>

const ParameterType = z.enum(['Sensor', 'Setting', 'Alarm'])

export const DeviceParameterDto = z.object({
  name: z.string(),
  type: ParameterType,
  valueType: z.string()
})
export type DeviceParameterDto = z.infer<typeof DeviceParameterDto>

export const DryerParametersResponse = z.array(DeviceParameterDto)
export type DryerParametersResponse = z.infer<typeof DryerParametersResponse>

export const Settings = z.record(z.string().optional())
export type Settings = z.infer<typeof Settings>

export const SettingsMap = Settings.transform(o => new Map(Object.entries(o)))
export type SettingsMap = z.infer<typeof SettingsMap>
