import { DryerId, SensorDeviceLocalId, SensorDeviceType } from '../api/types'

export const routes = {
  Home: '/',
  Reports: '/reports',
  Activity: '/activity',
  Login: '/login',
  ChangePassword: '/set-password',
  Dryer: '/dryers/:dryerId',
  Measurements: '/measurements/:dryerId',
  Sensors: '/devices/:deviceType/:deviceLuid/sensors'
}

// todo: use member's name in routes
export type DryerPathParam = {
  readonly dryerId: DryerId
}

export const dryerById = (id: string): string => `/dryers/${id}`
export const deviceSensorsById = (type: SensorDeviceType, luid: SensorDeviceLocalId): string => `/devices/${type}/${luid}/sensors`
