import * as React from 'react'
import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useAckAlarmMutation } from '../../../api/alarms'
import { AlarmId, AlertType } from '../../../api/types'
import { dateTimeShortFormat, useDateFormatter } from '../../../utils/date-utils'
import Button from '../../controls/Button'

export type AlarmInfo = {
  readonly id: AlarmId
  readonly timeStarted: Date
  readonly dryerName: string
  readonly sensor: AlertType
}

type Props = {
  readonly alarm: AlarmInfo
  readonly onComplete: React.Dispatch<boolean>
}

export const ConfirmAlarmDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const formatter = useDateFormatter(dateTimeShortFormat)
  const ack = useAckAlarmMutation(props.alarm.id)

  const handleClick: (confirmed: boolean) => React.MouseEventHandler =
    (confirmed) => (event) => {
      event.stopPropagation()
      event.preventDefault()
      if (confirmed) {
        ack.mutate(undefined, {
          onSettled: (_, error) => props.onComplete(error === undefined || error === null)
        })
      } else {
        props.onComplete(false)
      }
  }

  return (
    <Dialog open={true}>
      <DialogTitle disableTypography>
        <Typography variant='h5'>
          {t('dialog.alarms.new.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t('dialog.alarms.new.body', {
            sensor: t(`types.alarms.${props.alarm.sensor}`),
            time: formatter(props.alarm.timeStarted),
            dryer: props.alarm.dryerName
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth variant="text" aria-label='text primary button group'>
          <Button fullWidth variant='contained' color='secondary' size='large' onFocus={(e) => e.stopPropagation()} onClick={handleClick(false)}>
            {t('dialog.alarms.new.cancel')}
          </Button>
          <Button fullWidth variant='contained' color='primary' size='large' onFocus={(e) => e.stopPropagation()} onClick={handleClick(true)}>
            {t('dialog.alarms.new.submit')}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}
