import { DryerId } from '../../types'
import { apiBaseUrl } from '../../../constants/configuration'
import { UseQueryResult } from '../../util/types'
import { QueryKey } from 'react-query'
import { queryDateTime } from '../../index'
import { DataSample, DryingStatistic, MeasurementsResponse } from './types'
import { useApiQuery, UseApiQueryOptions, useReceiveOnly } from '../../util/hooks'
import { dryerKey } from '../'

export const getBaseMeasurementKey: (id: DryerId) => QueryKey =
  (dryerId) => [ ...dryerKey(dryerId), 'measurements' ]

const getMeasurementKey: (id: DryerId, from: Date, to?: Date, count?: number) => QueryKey = (
  dryerId,
  from,
  to,
  count
) => [ ...getBaseMeasurementKey(dryerId), from, to, count ]

export const getStatisticKey: (id: DryerId, from?: Date, to?: Date, window?: number) => QueryKey = (
  dryerId,
  from,
  to,
  window
) => [ ...dryerKey(dryerId), 'statistic', from, to, window ]

export const useMeasurementSampleQuery = (dryerId: DryerId, at: Date, opts?: UseApiQueryOptions<DataSample>): UseQueryResult<DataSample> =>
  useApiQuery(
    getMeasurementKey(dryerId, at),
    useReceiveOnly('get', `${apiBaseUrl}/dryers/${dryerId}/measurements/${queryDateTime(at)}`, DataSample),
    opts)

export const useMeasurementQuery = (
  dryerId: DryerId,
  from: Date,
  to?: Date,
  count?: number
): UseQueryResult<MeasurementsResponse> =>
  useApiQuery(
    getMeasurementKey(dryerId, from, to, count),
    useReceiveOnly('get', `${apiBaseUrl}/dryers/${dryerId}/measurements?from=${queryDateTime(from)}${to ? `&to=${queryDateTime(to)}` : ''}${count ? `&count=${count}` : ''}`, MeasurementsResponse)
  )

export const useStatisticsQuery = (
  dryerId: DryerId,
  windowInMinutes: number,
  fromDate: Date,
  toDate?: Date
): UseQueryResult<DryingStatistic> =>
  useApiQuery(
    getStatisticKey(dryerId, fromDate, toDate, windowInMinutes),
    useReceiveOnly('get', `${apiBaseUrl}/dryers/${dryerId}/statistics?from=${queryDateTime(fromDate)}${toDate ? `&to=${queryDateTime(toDate)}` : ''}&window=${windowInMinutes}`, DryingStatistic)
  )
