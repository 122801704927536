import * as z from 'zod'
import { DryerId, Duration, PortNumber, SensorDeviceLocalId, SensorDeviceType } from '../types'
import { ParameterMappingDto } from './mapping/types'

export const MappingConfigurationDto = z.object({
  template: z.string(),
  parameters: z.array(ParameterMappingDto)
})

export const TransportProtocol = z.enum(['TCP', 'UDP'])

const BaseDeviceConfiguration = z.object({
  deviceType: SensorDeviceType
})

export const WebPollerConfiguration = BaseDeviceConfiguration.extend({
  deviceType: z.literal(SensorDeviceType.enum.PlcWeb),
  pollingInterval: Duration,
  baseUrl: z.string().url().nonempty(),
  paths: z.array(z.string().nonempty())
})
export type WebPollerConfiguration = z.infer<typeof WebPollerConfiguration>

export const JsonPollerConfiguration = BaseDeviceConfiguration.extend({
  deviceType: z.literal(SensorDeviceType.enum.Json),
  pollingInterval: Duration,
  baseUrl: z.string().url().nonempty(),
  paths: z.array(z.string().nonempty())
})
export type JsonPollerConfiguration = z.infer<typeof JsonPollerConfiguration>

export const ModbusPollerConfiguration = BaseDeviceConfiguration.extend({
  deviceType: z.literal(SensorDeviceType.enum.Modbus),
  protocol: TransportProtocol,
  address: z.string(),
  port: PortNumber,
  pollingInterval: Duration,
  timeout: Duration
})
export type ModbusPollerConfiguration = z.infer<typeof ModbusPollerConfiguration>

export const PertenAM5200Configuration = BaseDeviceConfiguration.extend({
  deviceType: z.literal(SensorDeviceType.enum.PertenAM5200),
  pertenAddress: z.string()
})
export type PertenAM5200Configuration = z.infer<typeof PertenAM5200Configuration>

export const SensorDeviceConfigurationDto = z.union([
  PertenAM5200Configuration,
  ModbusPollerConfiguration,
  WebPollerConfiguration,
  JsonPollerConfiguration
])
export type SensorDeviceConfigurationDto = z.infer<typeof SensorDeviceConfigurationDto>

export const SensorDeviceInfo = z.object({
  luid: SensorDeviceLocalId,
  name: z.string().nonempty(),
  dryerIds: z.array(DryerId),
  mapping: MappingConfigurationDto,
  configuration: SensorDeviceConfigurationDto
})
export type SensorDeviceInfo = z.infer<typeof SensorDeviceInfo>

export const DeviceListResponse = z.array(SensorDeviceInfo)
export type DeviceListResponse = Readonly<z.infer<typeof DeviceListResponse>>

const RawSensorValueDto = z.object({
  sensor: z.string().nonempty(),
  reading: z.string().nonempty()
})
export type RawSensorValueDto = z.infer<typeof RawSensorValueDto>

export const PulledValueDto = z.object({
  sensorIndex: z.string(),
  parameter: z.string(),
  reading: z.string(),
  value: z.unknown()
})
export type PulledValueDto = z.infer<typeof PulledValueDto>

export const ProbeWithError = z.object({
  sensorIndex: z.string(),
  parameter: z.string(),
  reading: z.string()
})
export type ProbeWithError = z.infer<typeof ProbeWithError>

export const PullProbeDto = z.object({
  mapped: z.array(PulledValueDto),
  unmapped: z.array(RawSensorValueDto),
  conversionError: z.array(ProbeWithError),
  missingParameters: z.array(z.string())
})
export type PullProbeDto = z.infer<typeof PullProbeDto>

export const CreateDeviceRequest = z.object({
  name: z.string().nonempty(),
  mappingTemplate: z.string().nonempty(),
  configuration: SensorDeviceConfigurationDto
})
export type CreateDeviceRequest = z.infer<typeof CreateDeviceRequest>
