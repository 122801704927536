import * as z from 'zod'
import { nameOf } from '@digital-magic/ts-common-utils/lib/type'
import { Username } from '../users/types'

export type LoginProvider = 'native'

export const LoginRequest = z.object({
  userName: Username,
  password: z.string().nonempty()
})
export type LoginRequest = z.infer<typeof LoginRequest>

export const SessionPin = z.string().regex(RegExp('^\\d{4}$'))
export type SessionPin = z.infer<typeof SessionPin>

export const LoginResult = z.enum(['TechSessionCreated', 'NewPasswordRequired', 'WorkShiftCreated'])

const BaseLoginResponse = z.object({
  result: LoginResult
})

export const TechSessionCreatedResponse = BaseLoginResponse.extend({
  result: z.literal(LoginResult.enum.TechSessionCreated),
})

export const NewPasswordRequiredResponse = BaseLoginResponse.extend({
  result: z.literal(LoginResult.enum.NewPasswordRequired),
  token: z.string().nonempty()
})

export const WorkShiftOpen = BaseLoginResponse.extend({
  result: z.literal(LoginResult.enum.WorkShiftCreated),
  pin: SessionPin
})
export type WorkShiftOpen = z.infer<typeof WorkShiftOpen>

export const LoginResponse = z.union([TechSessionCreatedResponse, NewPasswordRequiredResponse, WorkShiftOpen])
export type LoginResponse = z.infer<typeof LoginResponse>

export const LogoutResponse = z.object({
  workShiftActive: z.boolean()
})
export type LogoutResponse = z.infer<typeof LogoutResponse>

export const passwordComplexityRefinement: Parameters<z.ZodString['refine']> = [
  (value: string): boolean => value.toLowerCase() !== value
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const ComplexPassword = z
  .string()
  .min(8)
  .max(32)
  .refine(...passwordComplexityRefinement)
export type ComplexPassword = z.infer<typeof ComplexPassword>

export const SetPasswordRequest = z.object({
  token: z.string().nonempty(),
  newPassword: ComplexPassword
})
export type SetPasswordRequest = z.infer<typeof SetPasswordRequest>

export const NewPasswordPath: string = nameOf<SetPasswordRequest>('newPassword')
