import { DryerId, Page, pageKey } from '../types'
import { apiBaseUrl } from '../../constants/configuration'
import { QueryKey, UseQueryResult } from 'react-query'
import {
  AlarmAssigned,
  AlarmCleared,
  AlarmFired,
  CreateLedgerRecordRequest,
  JournalEntry,
  JournalPage,
  JournalFacility,
  LedgerRecord,
  ManualMeasurementRecord,
  SettingChangedRecord,
  TodoCompletedRecord,
  TodoCreatedRecord, WorkShiftFinished,
  WorkShiftStarted, ForeignMeasurementRecord
} from './types'
import { UseMutationOptions, UseMutationResult } from '../util/types'
import { hasValue } from '@digital-magic/ts-common-utils/lib/type'
import { queryDateTime } from '../index'
import { useApiMutation, useApiQuery, UseApiQueryOptions, useReceiveOnly, useSendOnly } from '../util/hooks'
import { TFunction } from 'i18next'
import { flatMap } from '../../utils/function-utils'
import { mayBeType } from '../../utils/zod-utils'

const journalUrl = `${apiBaseUrl}/journal`

const journalQueryUrl: (
  dryerId?: DryerId,
  page?: Page,
  sources?: ReadonlyArray<JournalFacility>,
  from?: Date,
  to?: Date
) => string = (dryerId, page, sources, from, to) => {
  const url = new URL(journalUrl)
  if (hasValue(dryerId)) {
    url.searchParams.append('dryerId', dryerId)
  }
  if (hasValue(page)) {
    url.searchParams.append('page', page.nr.toString())
    url.searchParams.append('size', page.size.toString())
  }
  if (hasValue(sources)) {
    sources.forEach((s) => url.searchParams.append('kind', s))
  }
  if (hasValue(from)) {
    url.searchParams.append('from', queryDateTime(from))
  }
  if (hasValue(to)) {
    url.searchParams.append('to', queryDateTime(to))
  }
  return url.href
}

export const journalQueryKey: QueryKey = 'journal'

export const useActivityLogQuery = (
  sources?: ReadonlyArray<JournalFacility>,
  dryerId?: DryerId,
  page?: Page,
  from?: Date,
  to?: Date,
  opts?: UseApiQueryOptions<JournalPage>
): UseQueryResult<JournalPage> =>
  useApiQuery(
    [journalQueryKey, sources, dryerId, page ? pageKey(page) : undefined, from, to],
    useReceiveOnly('get', journalQueryUrl(dryerId, page, sources, from, to), JournalPage),
    opts
  )

export const useAddNewLedgerRecordMutation = (
  opts?: UseMutationOptions<void, CreateLedgerRecordRequest>
): UseMutationResult<void, CreateLedgerRecordRequest> =>
  useApiMutation(useSendOnly('post', journalUrl, CreateLedgerRecordRequest), {
    invalidateQueries: [journalQueryKey],
    ...opts
  })

export const journalMessage: (t: TFunction, prefix: string) => (e: JournalEntry) => string | undefined = (t, prefix) => (e) =>
  flatMap(mayBeType(e, AlarmFired), m => t(`${prefix}.${e.kind}`, { alert: m.alertType })) ??
  flatMap(mayBeType(e, AlarmAssigned), m => t(`${prefix}.${e.kind}`, { alert: m.alertType, assignee: m.source })) ??
  flatMap(mayBeType(e, AlarmCleared), m => t(`${prefix}.${e.kind}`, { alert: m.alertType })) ??
  flatMap(mayBeType(e, SettingChangedRecord), m => t(`${prefix}.${e.kind}`, { setting: m.setting, oldValue: m.oldValue, newValue: m.newValue, actor: m.source })) ??
  flatMap(mayBeType(e, ManualMeasurementRecord), m => t(`${prefix}.${e.kind}`, { grain: m.grainType, dry: m.dry, moisture: m.data.moisture, actor: m.source })) ??
  flatMap(mayBeType(e, ForeignMeasurementRecord), m => t(`${prefix}.${e.kind}`, { grain: m.grainType, dry: m.dry, moisture: m.data.moisture, actor: m.source })) ??
  flatMap(mayBeType(e, TodoCreatedRecord), m => t(`${prefix}.${e.kind}`, { task: m.task, author: m.source })) ??
  flatMap(mayBeType(e, TodoCompletedRecord), m => t(`${prefix}.${e.kind}`, { task: m.task, doneBy: m.source })) ??
  flatMap(mayBeType(e, LedgerRecord), m => t(`${prefix}.${e.kind}`, { author: m.source, message: m.message })) ??
  flatMap(mayBeType(e, WorkShiftStarted), m => t(`${prefix}.${e.kind}`, { worker: m.source, from: m.address })) ??
  flatMap(mayBeType(e, WorkShiftFinished), m => t(`${prefix}.${e.kind}`, { worker: m.source }))
