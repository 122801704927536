import * as z from 'zod'
import { StringMap, TOptions } from 'i18next'

export const ApiErrorCode = z.enum([
  'UnsupportedMediaType',
  'HttpObjectError',
  'JsonObjectError',
  'ConversionError',
  'SessionNotFound',
  'SessionInvalidOrExpired',
  'SessionPinRequired',
  'OperationForbidden',
  'LoginIsNotAllowedByOTP',
  'InvalidCredential',
  'TooManyResults',
  'TransactionFailure',
  'CurrencyIsNotValid',
  'MalformedConfig',
  'ExternalServiceError',
  'InternalError',
  'BCryptError',
  'UserNotFound',
  'IncorrectPassword',
  'PasswordIsNotStrongEnough',
  'InvalidConfirmationCode',
  'UserAlreadyExists',
  'InvalidEmail',
  'InvalidPhoneNumber',
  'CompanyNotFound',
  'ConfirmationCodeNotFound',
  'NotPermitted',
  'PlantNotFound',
  'PlantAddressNotUnique',
  'DryerNotFound',
  'DryerAlreadyBoundToDevice',
  'DryerIsNotActivated',
  'InvalidOperatorLoginName',
  'OperatorProfileNotFound',
  'OperatorProfileLoginShouldBeUnique',
  'PlcHttpClientError',
  'PlcMalformedHtml',
  'ServiceRequestNotFound',
  'AlarmNotFound',
  'SettingNotFound',
  'ServiceRequestStateCannotBeNull',
  'InvalidServiceRequestState',
  'DeviceNotFound',
  'DeviceAlreadyExist',
  'DeviceGotNewConnection',
  'DeviceMayBeBoundToSinglePlant',
  'DeviceAlreadyBoundToDryer',
  'InvalidDeviceToken',
  'PlantHasDeviceAssigned',
  'PlantMustHaveAtLeastOneDryer',
  'ServiceRequestAlreadyExists',
  'PlcNotFound',
  'PlcNoDataFound',
  'SensorMappingNotFound',
  'NoPlcConfiguredForDryer',
  'ReadOnlyValue',
  'CloudConnectionClosed',
  'SerialNumberIsNotAssigned',
  'TodoNotFound',
  'SampleNotFound',
  'PertenIsNotConfigured',
])
export type ApiErrorCode = z.infer<typeof ApiErrorCode>

export type AppError = Error & {
  readonly messageKey: string
  readonly messageOpts?: TOptions<StringMap>
}

export const ApiErrorObject = z.object({
  debugMessage: z.string().nullable(),
  extCode: ApiErrorCode,
  message: z.string().nullable()
})
export type ApiErrorObject = z.infer<typeof ApiErrorObject>

