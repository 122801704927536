import * as React from 'react'
import * as z from 'zod'
import { sessionStorageKey } from '../../constants/configuration'
import { useHistory } from 'react-router-dom'
import { useValidatedLocalStorage } from '../../hooks/StorageHooks'
import { routes } from '../../constants/routes'
import { ShowPinDialog } from '../dialogs/SessionPin/ShowPinDialog'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { SessionPin } from '../../api/auth/types'
import { mayBeType } from '../../utils/zod-utils'
import { flatMap, getOrElse } from '../../utils/function-utils'

export const SessionType = z.enum(['technician', 'workshift', 'unknown'])
export type SessionType = z.infer<typeof SessionType>

const LoginCase = z.enum(['technician', 'startWorkShift', 'continueWorkShift'])

const TechnicianLogin = z.object({
  loginType: z.literal(LoginCase.enum.technician),
  sessionType: z.literal(SessionType.enum.technician)
})
type TechnicianLogin = z.infer<typeof TechnicianLogin>

const StartShiftLogin = z.object({
  loginType: z.literal(LoginCase.enum.startWorkShift),
  sessionType: z.literal(SessionType.enum.workshift),
  pin: SessionPin
})
type StartShiftLogin = z.infer<typeof StartShiftLogin>

const ContinueShiftLogin = z.object({
  loginType: z.literal(LoginCase.enum.continueWorkShift),
  sessionType: z.literal(SessionType.enum.workshift),
})
type ContinueShiftLogin = z.infer<typeof ContinueShiftLogin>

const LoginType = z.union([TechnicianLogin, StartShiftLogin, ContinueShiftLogin])
type LoginType = z.infer<typeof LoginType>

export const technicianLogin: TechnicianLogin = {
  sessionType: SessionType.enum.technician,
  loginType: LoginCase.enum.technician
}
export const continueWorkShift: ContinueShiftLogin = {
  sessionType: SessionType.enum.workshift,
  loginType: LoginCase.enum.continueWorkShift
}
export const startWorkShift: (pin: string) => StartShiftLogin =
  (pin) => ({
    sessionType: SessionType.enum.workshift,
    loginType: LoginCase.enum.startWorkShift,
    pin
  })

export type WorkModeContextValue = {
  readonly login: React.Dispatch<LoginType>
  readonly logout: React.Dispatch<boolean | undefined>
  readonly sessionType: SessionType
}

export const WorkModeContext = React.createContext({} as WorkModeContextValue)

const BaseWorkModeState = z.object({
  state: SessionType
})
const UnknownModeState = BaseWorkModeState.extend({
  state: z.literal(SessionType.enum.unknown)
})
const WorkShiftModeState = BaseWorkModeState.extend({
  state: z.literal(SessionType.enum.workshift)
})
const TechWorkModeState = BaseWorkModeState.extend({
  state: z.literal(SessionType.enum.technician)
})
const WorkModeState = z.union([UnknownModeState, TechWorkModeState, WorkShiftModeState])
type WorkModeState = z.infer<typeof WorkModeState>

export const WorkModeContextProvider: React.FC = ({ children }) => {
  const [sessions, setSessions] = useValidatedLocalStorage(sessionStorageKey, {state: SessionType.enum.unknown}, WorkModeState)
  const history = useHistory()
  const [showPin, setShowPin] = React.useState<OptionalType<string>>(undefined)

  const login = React.useCallback((loginType: LoginType) => {
    setSessions({ state: loginType.sessionType })
    getOrElse<SessionType>(
      flatMap<TechnicianLogin, SessionType>(mayBeType(loginType, TechnicianLogin), (l) => {
      history.push(routes.Home)
      return l.sessionType
    }) ?? flatMap<StartShiftLogin, SessionType>(mayBeType(loginType, StartShiftLogin), (l) => {
      setShowPin(l.pin)
      return l.sessionType
    }) ?? flatMap<ContinueShiftLogin, SessionType>(mayBeType(loginType, ContinueShiftLogin), (l) => {
      history.push(routes.Home)
      return l.sessionType
    }), () => {
      console.error('unknown login case', loginType)
      return SessionType.enum.unknown
    })
  }, [setSessions, history])

  const logout = React.useCallback((workShiftPresent: boolean | undefined) => {
    setSessions(workShiftPresent === true ? { state: SessionType.enum.workshift } : { state: SessionType.enum.unknown })
    history.push(workShiftPresent ? routes.Home : routes.Login)
  }, [history, setSessions])

  const handleCompleteLoginFlow: React.DispatchWithoutAction = () => {
    setShowPin(undefined)
    history.push(routes.Home)
  }

  return (
    <WorkModeContext.Provider
      value={{
        login,
        logout,
        sessionType: sessions.state
      }}
    >
      {showPin && <ShowPinDialog pin={showPin} close={handleCompleteLoginFlow} />}
      {children}
    </WorkModeContext.Provider>
  )
}
