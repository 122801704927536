import * as z from 'zod'
import { SensorDeviceType } from '../../types'

export const TransformerName = z.enum([
  'StringToLong',
  'StringToDecimal',
  'StringToBoolean',
  'StringToLocalDate',
  'StringToLocalTime',
  'LongToDecimal',
  'LongToDuration',
  'DecimalScaleBy10',
  'DecimalBandwidthFilter',
  'IntegerScaleBy10',
  'BitExtractor',
  'ModbusWordsToLong',
  'LongToTimestamp',
  'DecimalToLong'
])

const BaseTransformerDto = z.object({
  transformation: TransformerName
})

export const StringToLong = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.StringToLong)
})

export const StringToDecimal = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.StringToDecimal)
})

export const StringToBoolean = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.StringToBoolean)
})

export const StringToLocalTime = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.StringToLocalTime)
})

export const StringToLocalDate = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.StringToLocalDate)
})

export const LongToDecimal = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.LongToDecimal)
})

export const ChronoUnit = z.enum(['NANOS','MICROS','MILLIS','SECONDS','MINUTES','HOURS'])

export const LongToDuration = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.LongToDuration),
  unit: ChronoUnit
})
export type LongToDuration = z.infer<typeof LongToDuration>

export const ExponentValue = z.number().int().min(-10).max(10)

export const DecimalScaleBy10Transformer = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.DecimalScaleBy10),
  times: ExponentValue
})
export type DecimalScaleBy10Transformer = z.infer<typeof DecimalScaleBy10Transformer>

export const DecimalBandwidthFilter = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.DecimalBandwidthFilter),
  lowBoundary: z.number(),
  highBoundary: z.number()
}).refine((o) => o.lowBoundary <= o.highBoundary, 'Low boundary must be less or equal high boundary')
export type DecimalBandwidthFilter = z.infer<typeof DecimalBandwidthFilter>

export const IntegerScaleBy10Transformer = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.IntegerScaleBy10),
  times: ExponentValue
})
export type IntegerScaleBy10Transformer = z.infer<typeof IntegerScaleBy10Transformer>

export const BitOrdinal = z.number().int().min(0).max(31)

export const BitExtractorTransformer = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.BitExtractor),
  bit: BitOrdinal
})
export type BitExtractorTransformer = z.infer<typeof BitExtractorTransformer>

export const ModbusWordCount = z.number().int().min(1).max(2)

export const ModbusWordsToLongTransformer = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.ModbusWordsToLong),
  words: ModbusWordCount,
  bigEndian: z.boolean()
})
export type ModbusWordsToLongTransformer = z.infer<typeof ModbusWordsToLongTransformer>

export const LongToTimestamp = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.LongToTimestamp)
})

export const DecimalToLong = BaseTransformerDto.extend({
  transformation: z.literal(TransformerName.enum.DecimalToLong)
})

export const TransformerDto = z.union([
  StringToLong,
  StringToDecimal,
  StringToBoolean,
  StringToLocalTime,
  StringToLocalDate,
  LongToDecimal,
  LongToDuration,
  DecimalScaleBy10Transformer,
  DecimalBandwidthFilter,
  IntegerScaleBy10Transformer,
  BitExtractorTransformer,
  ModbusWordsToLongTransformer,
  LongToTimestamp,
  DecimalToLong
])
export type TransformerDto = z.infer<typeof TransformerDto>

export const ParameterMappingDto = z.object({
  deviceName: z.string(),
  parameter: z.string(),
  transformers: z.array(TransformerDto)
})
export type ParameterMappingDto = z.infer<typeof ParameterMappingDto>

export const MappingTemplateDto = z.object({
  name: z.string().nonempty(),
  type: SensorDeviceType,
  parameters: z.array(ParameterMappingDto)
})

export const MappingResponse = z.array(ParameterMappingDto)
export type MappingResponse = z.infer<typeof MappingResponse>

export const MappingTemplateList = z.array(MappingTemplateDto)
export type MappingTemplateList = z.infer<typeof MappingTemplateList>

export const MappingRequest = z.object({
  parameter: z.string(),
  deviceName: z.string().nullish(),
  transformers: z.array(TransformerDto)
})
export type MappingRequest = z.infer<typeof MappingRequest>

