import * as React from 'react'
import { ButtonGroup } from '@material-ui/core'
import Button from '../controls/Button'
import { useTranslation } from 'react-i18next'
import DebugFormButton from '../controls/DebugFormButton'
import { UseFormResult } from '../controls/Form/Form'
import { isDebugForms } from '../../constants/configuration'

type Props<T> = {
  readonly onCancel: React.DispatchWithoutAction
  readonly form?: UseFormResult<T>
  readonly noTextKey?: string
  readonly yesTextKey?: string
}

export const FormButtons = <T,>(props: Props<T>): JSX.Element => {
  const { t } = useTranslation()

  const handleCancel: React.MouseEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    props.onCancel()
  }

  return (
    <ButtonGroup fullWidth variant="text" aria-label='text primary button group'>
      <Button fullWidth variant='contained' color='secondary' size='large' onClick={handleCancel} onFocus={(e) => e.stopPropagation()}>
        {t(props.noTextKey ?? 'global.buttons.back')}
      </Button>
      {isDebugForms && props.form && <DebugFormButton f={props.form} />}
      <Button fullWidth variant='contained' color='primary' size='large' type='submit' onFocus={(e) => e.stopPropagation()}>
        {t(props.yesTextKey ?? 'global.buttons.submit')}
      </Button>
    </ButtonGroup>
  )
}
