import { hasValue, NullableType, NullUnionType, OptionalType } from '@digital-magic/ts-common-utils/lib/type'

export const throttle = (cb: () => void, delay: number): (() => void) => {
  // eslint-disable-next-line functional/no-let
  let timeout: NodeJS.Timeout | null = null

  return () => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      cb()
      timeout = null
    }, delay)
  }
}

export type LazyValue<T> = (() => T) | T
export const evaluate: <A>(value: LazyValue<A>) => A = (value) => value instanceof Function ? value() : value

export const tryOrElse = <T>(fn: () => T, defaultValue: LazyValue<T>): T => {
  // eslint-disable-next-line functional/no-try-statement
  try {
    return fn()
  } catch (e) {
    return evaluate(defaultValue)
  }
}

export const fmap: <A,B>(f: (a: A) => B) => (v: OptionalType<A>) => OptionalType<B> = (f) => (v) => v !== undefined ? f(v) : undefined

export const optional: <A>(value: OptionalType<A> | NullableType<A> | NullUnionType<A>) => OptionalType<A> = (value) => hasValue(value) ? value : undefined
export const flatMap: <A,B>(value: OptionalType<A> | NullableType<A> | NullUnionType<A>, f: (a: A) => B) => OptionalType<B> = (value, f) => fmap(f)(optional(value))
export const getOrElse: <A>(value: OptionalType<A>, defaultValue: LazyValue<A>) => A = (value, defaultValue) => value ?? evaluate(defaultValue)
