import * as z from 'zod'
import { enGB, ru, enUS, et } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'

export const SupportedLanguage = z.enum([ 'en', 'ru', 'ee' ])
export type SupportedLanguage = z.infer<typeof SupportedLanguage>

type LocaleMappingEntry = {
  readonly prefix: string
  readonly locale: Locale
}
const localeM: (prefix: string, locale: Locale) => LocaleMappingEntry = (prefix, locale) => ({ prefix, locale })

export function useCurrentLocale(): Locale {
  const { i18n } = useTranslation()
  const defaultLocale = enGB
  const mapping: ReadonlyArray<LocaleMappingEntry> = [
    localeM('en-US', enUS),
    localeM('en-GB', enGB),
    localeM('ru', ru),
    localeM('et', et)
  ]

  return mapping.find((e) => i18n.language.startsWith(e.prefix))?.locale ?? defaultLocale
}

type LanguageMappingEntry = {
  readonly prefix: string
  readonly language: SupportedLanguage
}
const langM: (prefix: string, language: SupportedLanguage) => LanguageMappingEntry = (prefix, language) => ({ prefix, language })
export function useCurrentLanguage(): SupportedLanguage {
  const { i18n } = useTranslation()
  const defaultLanguage = SupportedLanguage.enum.en
  const mapping: ReadonlyArray<LanguageMappingEntry> = [
    langM('en', SupportedLanguage.enum.en),
    langM('ru', SupportedLanguage.enum.ru),
    langM('et', SupportedLanguage.enum.ee)
  ]

  return mapping.find((e) => i18n.language.startsWith(e.prefix))?.language ?? defaultLanguage
}
