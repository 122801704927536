import * as React from 'react'
import {
  DisarmedAlarm,
  MessageType,
  NewAlarm,
  NewMoistureMeasurement,
  NewTodo,
  NotificationContext,
  TodoReminder
} from '../../context/NotificationContext'
import { useTranslation } from 'react-i18next'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { AlarmInfo, ConfirmAlarmDialog } from '../../dialogs/Alarms/ConfirmAlarmDialog'
import { ConfirmMoistureDialog } from '../../dialogs/Moisture/ConfirmMoistureDialog'
import { useSnackbar } from 'notistack'
import { ConfirmedMoistureMeasurement, NewLedgerMessage, TodoDone } from '../../context/NotificationContext/types'
import { ShowReminderDialog } from '../../dialogs/Todos/ShowReminderDialog'
import { useQueryClient } from 'react-query'
import { getAlarmsQueryKey } from '../../../api/alarms'
import { todoQueryKey } from '../../../api/todos'
import { journalQueryKey } from '../../../api/journal'
import { getBaseMeasurementKey, getStatisticKey } from '../../../api/dryers/measurements'
import { DryerId } from '../../../api/types'
import { allDryersQueryKey, dryerKey } from '../../../api/dryers'
import { timeFormat, useDateFormatter } from '../../../utils/date-utils'

type MoistureMessage = {
  readonly messageId: string
  readonly sample: NewMoistureMeasurement
}

type ReminderMessage = {
  readonly messageId: string
  readonly reminder: TodoReminder
}

type AlarmMessage = {
  readonly messageId: string
  readonly alarm: NewAlarm
}

const alarm2info: (alarm: NewAlarm) => AlarmInfo = (alarm) => ({
  id: alarm.id,
  sensor: alarm.alert,
  dryerName: alarm.dryer.name,
  timeStarted: new Date(alarm.startedAt)
})

const NotificationCenter: React.FC = () => {
  const queryClient = useQueryClient()
  const { lastMessage, seenMessage } = React.useContext(NotificationContext)
  const { enqueueSnackbar } = useSnackbar()
  const [showMoistureSample, setShowMoistureSample] = React.useState<OptionalType<MoistureMessage>>(undefined)
  const [showReminder, setShowReminder] = React.useState<OptionalType<ReminderMessage>>(undefined)
  const [showAlarm, setShowAlarm] = React.useState<OptionalType<AlarmMessage>>(undefined)
  const { t } = useTranslation()
  const dtFormat = useDateFormatter(timeFormat)

  const disarmedAlarm: (msgid: string, alarm: DisarmedAlarm) => void = React.useCallback(
    (msgid, alarm) => {
      enqueueSnackbar(t('messages.alarms.disarmed', { sensor: t(`types.alarms.${alarm.alert}`) }), {
        key: `messages.alarms.disarmed-${alarm.alert}`,
        variant: 'info'
      })
      seenMessage(msgid)
      void queryClient.invalidateQueries(getAlarmsQueryKey)
    },
    [seenMessage, enqueueSnackbar, t, queryClient]
  )

  const newLogMessage: (newLog: NewLedgerMessage) => void = React.useCallback(
    (newLog) => {
    enqueueSnackbar(
      newLog.dryer
        ? t('messages.journal.new-received-for-dryer', {
          author: newLog.author,
          text: newLog.text,
          dryer: newLog.dryer.name
        })
        : t('messages.journal.new-received', {
          author: newLog.author,
          text: newLog.text
        }),
      {
        key: 'messages.todos.new',
        variant: 'info'
      }
    )
    void queryClient.invalidateQueries(journalQueryKey)
  }, [enqueueSnackbar, t, queryClient])

  const newTodo: (newTodo: NewTodo) => void = React.useCallback(
    (newTodo) => {
      enqueueSnackbar(
          newTodo.dryer
            ? t('messages.todos.new-received-for-dryer', {
                author: newTodo.author,
                task: newTodo.task,
                dryer: newTodo.dryer.name
              })
            : t('messages.todos.new-received', {
                author: newTodo.author,
                task: newTodo.task
              }),
        {
          key: `messages.todos.new-${newTodo.id}`,
          variant: 'info'
        }
      )
      void queryClient.invalidateQueries(todoQueryKey)
    },
    [enqueueSnackbar, t, queryClient]
  )

  const todoCompleted: (todo: TodoDone) => void = React.useCallback(
    (todo) => {
      enqueueSnackbar(t('messages.todos.done-received', {
          performer: todo.performer
        }),
        {
          key: `messages.todos.done-${todo.id}`,
          variant: 'info'
        }
      )
      void queryClient.invalidateQueries(todoQueryKey)
    },
    [enqueueSnackbar, t, queryClient]
  )

  const alarmShown:  (messageId: string) => React.Dispatch<boolean> = React.useCallback(
    (messageId) => (confirmed) => {
      seenMessage(messageId)
      setShowAlarm(undefined)
      if (confirmed)
        enqueueSnackbar(t('messages.alarms.ack-done'), {
          key: 'messages.alarms.ack-done',
          variant: 'success'
        })
      else
        enqueueSnackbar(t('messages.alarms.skipped'), {
          key: 'messages.alarms.ack-skipped',
          variant: 'warning'
        })
    },
    [enqueueSnackbar, t, seenMessage, setShowAlarm]
  )

  const reminderShown: (messageId: string) => React.Dispatch<boolean> = React.useCallback(
    (messageId) => (confirmed) => {
      seenMessage(messageId)
      setShowReminder(undefined)
      if (confirmed)
        enqueueSnackbar(t('messages.todos.mark-done'), {
          key: 'messages.todos.mark-done',
          variant: 'success'
        })
      else
        enqueueSnackbar(t('messages.todos.disarmed'), {
          key: 'messages.todos.disarmed',
          variant: 'warning'
        })
      void queryClient.invalidateQueries(todoQueryKey)
    },
    [enqueueSnackbar, t, seenMessage, setShowReminder, queryClient]
  )

  const newMoistureSample: (messageId: string, data: NewMoistureMeasurement) => void =
    React.useCallback((messageId, data) => {
      seenMessage(messageId)
      setShowMoistureSample({ messageId, sample: data})
    }, [seenMessage, setShowMoistureSample])

  const moistureSampleShown: React.Dispatch<OptionalType<DryerId>> = React.useCallback(
     (confirmedTo) => {
      setShowMoistureSample(undefined)
      if (confirmedTo !== undefined) {
        enqueueSnackbar(t('messages.moisture.confirmed'), {
          key: 'messages.moisture.confirmed',
          variant: 'success'
        })
        void queryClient.invalidateQueries(getBaseMeasurementKey(confirmedTo))
        void queryClient.invalidateQueries(getStatisticKey(confirmedTo))
        void queryClient.invalidateQueries(dryerKey(confirmedTo))
        void queryClient.invalidateQueries(allDryersQueryKey)
      } else
        enqueueSnackbar(t('messages.moisture.skipped'), {
          key: 'messages.moisture.skipped',
          variant: 'warning'
        })
    },
    [enqueueSnackbar, t, setShowMoistureSample, queryClient]
  )

  const someoneConfirmedMoistureSample: (messageId: string, data: ConfirmedMoistureMeasurement) => void = React.useCallback(
    (messageId, data) => {
      if (showMoistureSample &&
        showMoistureSample.sample.resultId === data.resultId &&
        showMoistureSample.sample.deviceId === data.deviceId
      ) {
        setShowMoistureSample(undefined)
      }
      void queryClient.invalidateQueries(getBaseMeasurementKey(data.dryerId))
      void queryClient.invalidateQueries(getStatisticKey(data.dryerId))
      void queryClient.invalidateQueries(dryerKey(data.dryerId))
      void queryClient.invalidateQueries(allDryersQueryKey)
      seenMessage(messageId)
      enqueueSnackbar(t('messages.moisture.someone-confirmed', { actor: data.confirmedBy, sample: data.resultId, at: dtFormat(data.timestamp) }), {
        key: 'messages.moisture.someone-confirmed',
        variant: 'success'
      })
    },
    [queryClient, showMoistureSample, setShowMoistureSample, seenMessage, dtFormat, enqueueSnackbar, t])

  React.useEffect(() => {
    if (lastMessage) {
      switch (lastMessage.message) {
        case MessageType.enum.NewAlarm:
          setShowAlarm({ messageId: lastMessage.luid, alarm: lastMessage })
          void queryClient.invalidateQueries(getAlarmsQueryKey)
          break
        case MessageType.enum.DisarmedAlarm:
          disarmedAlarm(lastMessage.luid, lastMessage)
          break
        case MessageType.enum.NewTodo:
          newTodo(lastMessage)
          void queryClient.invalidateQueries(todoQueryKey)
          break
        case MessageType.enum.TodoReminder:
          setShowReminder({ messageId: lastMessage.luid, reminder: lastMessage })
          break
        case MessageType.enum.TodoDone:
          todoCompleted(lastMessage)
          void queryClient.invalidateQueries(todoQueryKey)
          break
        case MessageType.enum.NewMoistureMeasurement:
          newMoistureSample(lastMessage.luid, lastMessage)
          break
        case MessageType.enum.ConfirmedMoistureMeasurement:
          someoneConfirmedMoistureSample(lastMessage.luid, lastMessage)
          break
        case MessageType.enum.NewLedger:
          newLogMessage(lastMessage)
          break
        case MessageType.enum.NewData:
          void queryClient.invalidateQueries(allDryersQueryKey)
          void queryClient.invalidateQueries(dryerKey(lastMessage.dryer.id))
          void queryClient.invalidateQueries(getBaseMeasurementKey(lastMessage.dryer.id))
          void queryClient.invalidateQueries(getStatisticKey(lastMessage.dryer.id))
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage, seenMessage, queryClient])

  return (
    <React.Fragment>
      {showMoistureSample &&
        <ConfirmMoistureDialog
          sample={showMoistureSample.sample}
          onComplete={moistureSampleShown}
        />
      }
      {showReminder &&
        <ShowReminderDialog
          reminder={showReminder.reminder}
          onComplete={reminderShown(showReminder.messageId)}
        />
      }
      {showAlarm &&
        <ConfirmAlarmDialog
          alarm={alarm2info(showAlarm.alarm)}
          onComplete={alarmShown(showAlarm.messageId)}
        />
      }
    </React.Fragment>
  )
}

export default NotificationCenter
