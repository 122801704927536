import React from 'react'
import { useTranslation } from 'react-i18next'
import { SessionPin } from '../../../api/auth/types'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import Button from '../../controls/Button'

type Props = {
  readonly pin: SessionPin
  readonly close: () => void
}

export const ShowPinDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  return (
    <Dialog open={true}>
      <DialogTitle disableTypography>
        <Typography variant='h5'>
          {t('dialog.work-shift-pin.message')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h1' color='primary'>
          {props.pin}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant='contained' color='primary' size='large' onClick={props.close}>
          {t('dialog.work-shift-pin.btn-ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
