import * as z from 'zod'
import { Primitive, PrimitiveRecord } from '../../utils/type-utils'
import { Serializer, StoreHookResult } from './types'
import { useSerializedLocalStorage } from './useLocalStorage'
import { hasValue } from '@digital-magic/ts-common-utils/lib/type'
import { tryOrElse } from '../../utils/function-utils'

type PersistentType = Primitive | ReadonlyArray<Primitive>
type PersistentRecursiveType = PrimitiveRecord<PersistentType> | ReadonlyArray<PrimitiveRecord<PersistentType>>
export type ZodPersistentType = z.ZodType<PersistentType | PersistentRecursiveType>

export const zodSerializer = <V extends ZodPersistentType>(validator: V): Serializer<z.infer<V>> => ({
  serialize: (value) => (hasValue(value) ? JSON.stringify(value) : undefined),
  deserialize: (value, defaultValue) => {
    if (hasValue(value)) {
      const parsed = tryOrElse<unknown>(
        () => JSON.parse(value),
        () => undefined
      )
      if (hasValue(parsed)) {
        const validated = validator.safeParse(parsed)
        if (validated.success) {
          return validated.data
        }
        console.error('Unable to deserialize value: ', validated.error)
      }
      return defaultValue()
    } else {
      return defaultValue()
    }
  }
})

export const useValidatedLocalStorage = <V extends ZodPersistentType>(
  key: string,
  initialValue: z.infer<V>,
  validator: V
): StoreHookResult<z.infer<V>> => useSerializedLocalStorage(key, zodSerializer(validator), initialValue)
