import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {FormControl, MenuItem, Select} from '@material-ui/core'

type LangSelectValue = { readonly name?: string | undefined; readonly value: unknown; }

const LanguageSelect: React.FC = () => {
  const {i18n, t} = useTranslation()

  const onLanguageSwitched: (e: React.ChangeEvent<LangSelectValue>) => Promise<void> = async (e: React.ChangeEvent<LangSelectValue>) => {
    e.preventDefault()
    await i18n.changeLanguage(e.target.value as string)
  }

  return (
    <FormControl className="form-ctl-lang-select">
      <Select
        id="ctl-lang-select"
        value={i18n.language}
        onChange={onLanguageSwitched}
      >
        <MenuItem value='et'>{t('app.languages.et')}</MenuItem>
        <MenuItem value='ru'>{t('app.languages.ru')}</MenuItem>
        <MenuItem value='en'>{t('app.languages.en')}</MenuItem>
      </Select>
    </FormControl>
  )
}

export default LanguageSelect
