import { QueryClient } from 'react-query'
import {millisecondsInSecond, secondsInMinute, startOfMinute} from 'date-fns'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * secondsInMinute * millisecondsInSecond, // 30 minutes
      refetchInterval: 2 * secondsInMinute * millisecondsInSecond, // 2 minutes,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      suspense: false,
      useErrorBoundary: true
    },
    mutations: {
      useErrorBoundary: true
    }
  }
})

export const queryDateTime = (d: Date): string => startOfMinute(d).toISOString()
