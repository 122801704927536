import * as z from 'zod'
import { QueryKey } from 'react-query'

export const Moisture = z.number().positive().max(100)
export type Moisture = z.infer<typeof Moisture>

export const Temperature = z.number().positive().max(100)
export type Temperature = z.infer<typeof Temperature>

export const Duration = z.string().regex(RegExp('PT(\\d+(\\.\\d*)?[SMH])+'))
export type Duration = z.infer<typeof Duration>

export const PortNumber = z.number().int().min(1).max(65535)
export type PortNumber = z.infer<typeof PortNumber>

export const UserId = z.string().nonempty().uuid()
export type UserId = z.infer<typeof UserId>

export const DryerId = z.string().nonempty().uuid()
export type DryerId = z.infer<typeof DryerId>

export const SensorDeviceType = z.enum(['PertenAM5200', 'PlcWeb', 'Json', 'Modbus', 'Manual'])
export type SensorDeviceType = z.infer<typeof SensorDeviceType>

export const HttpPollerType = z.enum([SensorDeviceType.enum.Json, SensorDeviceType.enum.PlcWeb])
export type HttpPollerType = z.infer<typeof HttpPollerType>

export const SensorDeviceLocalId = z.string().nonempty().uuid()
export type SensorDeviceLocalId = z.infer<typeof SensorDeviceLocalId>

export const SensorDeviceId = z.object({
  type: SensorDeviceType,
  luid: SensorDeviceLocalId
})
export type SensorDeviceId = z.infer<typeof SensorDeviceId>

export const AlarmId = z.string().nonempty().uuid()
export type AlarmId = z.infer<typeof AlarmId>

export const TodoId = z.string().nonempty().uuid()
export type TodoId = z.infer<typeof TodoId>

export const DryerDescription = z.object({
  id: DryerId,
  name: z.string().nonempty(),
  cloudId: z.string().nonempty(),
  allowManual: z.boolean()
})
export type DryerDescription = z.infer<typeof DryerDescription>

export const GrainType = z.enum(['UNKNOWN', 'WHEAT', 'RAPE', 'RYE', 'BARLEY', 'OAT', 'MILLET', 'CORN', 'PEAS', 'BEANS'])
export type GrainType = z.infer<typeof GrainType>

export const SettingKey = z.enum([
  'DRYER_MODE',
  'GRAIN_TYPE',
  'GRAIN_TARGET_CONDITION',
  'MOISTURE_LOWER_BOUNDARY',
  'MOISTURE_UPPER_BOUNDARY',
  'OUTPUT_HOPPER_FULL_VOLUME',
  'OUTPUT_HOPPER_FULL_LOADTIME'
])
export type SettingKey = z.infer<typeof SettingKey>

export const AlertType = z.enum([
  'AirOverheated',
  'LowAirPressure',
  'BurnerHighTemperature',
  'General',
  'LoadingIssue',
  'DropAnomaly',
  'HopperFull'
])
export type AlertType = z.infer<typeof AlertType>

export const FilterOperator = z.enum(['equals', 'contains', 'startsWith', 'endsWith'])

export const Filter = z.object({
  operator: FilterOperator,
  term: z.string().nonempty()
})
export type Filter = z.infer<typeof Filter>

export const Page = z.object({
  nr: z.number().positive(),
  size: z.number().positive()
})
export type Page = z.infer<typeof Page>

export const pageKey: (p: Page) => QueryKey = (p) => [p.nr, p.size]
export const filterKey: (f: Filter) => QueryKey = (f) => [f.operator, f.term]

