import { Controller, useFormContext } from 'react-hook-form'
import SelectField, { SelectFieldProps, SelectValueType, ValueLabel } from '../SelectField'
import { FormInputProps } from './types'
import { useFormInputProps } from './util'
import { hasValue, OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import React from 'react'

type Props<T extends SelectValueType> = FormInputProps<OptionalType<T>> &
  Omit<SelectFieldProps<T>, 'name' | 'value' | 'onChange' | 'defaultValue'> & {
    readonly emptyValue?: T
    readonly defaultValue?: T
    readonly disableEmptyValue?: boolean
  }

const FormSelectField = <T extends SelectValueType>({
  emptyValue,
  disableEmptyValue,
  options,
  ...props
}: Props<T>): JSX.Element => {
  const f = useFormContext()
  const inputProps = useFormInputProps(props)

  const internalEmptyValue: T = React.useMemo(() => (hasValue(emptyValue) ? emptyValue : '') as T, [emptyValue])

  const internalOptions: ReadonlyArray<ValueLabel<T>> = React.useMemo(() => {
    const emptyOption: ReadonlyArray<ValueLabel<T>> = [
      { label: props.placeholder, value: internalEmptyValue, disabled: disableEmptyValue }
    ]
    return hasValue(props.placeholder) ? emptyOption.concat(options) : options
  }, [disableEmptyValue, options, props.placeholder, internalEmptyValue])

  const input = React.useCallback(
    (value: OptionalType<T>): T => (hasValue(value) ? value : internalEmptyValue),
    [internalEmptyValue]
  )
  const output = React.useCallback(
    (value: T): OptionalType<T> => (value === internalEmptyValue ? undefined : value),
    [internalEmptyValue]
  )

  return (
    <Controller
      control={f.control}
      name={inputProps.name}
      defaultValue={props.defaultValue}
      render={({ field }) => (
        <SelectField
          //InputLabelProps={{ shrink: true }}
          {...props}
          {...inputProps}
          {...field}
          options={internalOptions}
          inputProps={{ ...props.inputProps }}
          value={input(field.value)}
          onChange={(e) => field.onChange(output(e.target.value as T))}
        />
      )}
    />
  )
}

export default FormSelectField
