import { useTranslation } from 'react-i18next'
import Button from '../Button'
import { UseFormReturn } from 'react-hook-form'

type Props<T> = {
  readonly f: UseFormReturn<T>
}

const DebugFormButton = <T,>({ f }: Props<T>): JSX.Element => {
  const { t } = useTranslation()

  const checkForm: React.MouseEventHandler = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    console.log(f.getValues())
    console.log(f.formState.errors)
    await f.trigger()
  }

  return (
    <Button fullWidth variant="contained" size="large" onClick={checkForm} onFocus={(e) => e.stopPropagation()}>
      {t('check')}
    </Button>
  )
}

export default DebugFormButton
