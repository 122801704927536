import { createGlobalStyle, css } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    html {
      font-size: ${theme.typography.fontSize};
    }

    body {
      font-family: ${theme.typography.fontFamily};
      background-color: ${theme.palette.background.default};
    }
  `}
`
