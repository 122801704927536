import * as z from 'zod'
import { DryerId, GrainType, Moisture, SensorDeviceLocalId, Temperature } from '../../types'

export const PertenResultId = z.number()
export type PertenResultId = z.infer<typeof PertenResultId>

export const PertenSampleId = z.object({
  resultId: PertenResultId,
  pertenId: SensorDeviceLocalId
})
export type PertenSampleId = z.infer<typeof PertenSampleId>

export const PertenSampleData = z.object({
  grainProfile: z.string(),
  moisture: Moisture,
  natureKgPerLitre: z.number(),
  temperature: Temperature,
  timestamp: z.string(), // localdatetime
})
export type PertenSampleData = z.infer<typeof PertenSampleData>

export const PertenSample = z.object({
  recordId: PertenSampleId,
  data: PertenSampleData,
  address: z.string(),
  receivedAt: z.string(),  // instant
  processedAt: z.string().nullish(), // instant
  comment: z.string().nullish()
})
export type PertenSample = z.infer<typeof PertenSample>

export const PertenSampleList = z.array(PertenSample)
export type PertenSampleList = z.infer<typeof PertenSampleList>

export const PertenProcessSampleRequest = z.object({
  dryerId: DryerId,
  grainType: GrainType,
  isDry: z.boolean(),
  comment: z.string().optional()
})
export type PertenProcessSampleRequest = z.infer<typeof PertenProcessSampleRequest>
