import * as React from 'react'
import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { hasValue } from '@digital-magic/ts-common-utils/lib/type'
import { TodoReminder } from '../../context/NotificationContext'
import Button from '../../controls/Button'
import { useCompleteTodoMutation, useDisarmTodoMutation } from '../../../api/todos'

type Props = {
  readonly reminder: TodoReminder
  readonly onComplete: React.Dispatch<boolean>
}

export const ShowReminderDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const todoDone = useCompleteTodoMutation(props.reminder.id)
  const todoDisarm = useDisarmTodoMutation(props.reminder.id)

  const handleClick: (confirmed: boolean) => React.MouseEventHandler =
    (confirmed) => (event) => {
    event.stopPropagation()
    event.preventDefault()
      if (confirmed) {
        todoDone.mutate(undefined, {
          onSettled: (_, error) => props.onComplete(error === undefined || error === null)
        })
      } else {
        todoDisarm.mutate(undefined, {
          onSettled: () => props.onComplete(false)
        })
      }
  }

  return (
    <Dialog open={true}>
      <DialogTitle disableTypography>
        <Typography>
          {t('dialog.todos.reminder.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {hasValue(props.reminder.dryer)
            ? t('dialog.todos.reminder.body-with-dryer', {
              task: props.reminder.task,
              dryer: props.reminder.dryer
            })
            : t('dialog.todos.reminder.body', {
              task: props.reminder.task
            })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth variant="text" aria-label='text primary button group'>
          <Button fullWidth variant='contained' color='secondary' size='large' onFocus={(e) => e.stopPropagation()} onClick={handleClick(false)}>
            {t('dialog.todos.reminder.cancel')}
          </Button>
          <Button fullWidth variant='contained' color='primary' size='large' onFocus={(e) => e.stopPropagation()} onClick={handleClick(true)}>
            {t('dialog.todos.reminder.submit')}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}
