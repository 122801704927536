import { CustomThemeOptions } from './types'

export const lightTheme: CustomThemeOptions = {
  palette: {
    type: 'light',
    border: '#E6E6E6',
    background: {
      default: '#F6F4F5'
    },
    text: {
      primary: '#292116',
    },
    warning: {
      main: '#f39c3f'
    },
    primary: {
      main: '#8DC481',
      contrastText: '#FFF'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Red Hat Display, sans-serif',
    h1: {
      fontWeight: 'bolder'
    },
    h2: {
      fontWeight: 'bolder'
    },
    h3: {
      fontWeight: 'bolder'
    },
    h4: {
      fontWeight: 'bolder'
    },
    h5: {
      fontWeight: 'bolder'
    }
  },
  overrides: {
    MuiButton: {
      sizeLarge: {
        padding: '0.75rem',
        fontWeight: 'bold'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#8DC481'
      }
    },
    MuiCard: {
      root: {
        boxShadow: '0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.05)'
      }
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
        marginBottom: '0.125rem'
      }
    },
    MuiCardHeader: {
      root: {
        paddingTop: 0,
        paddingLeft: 8,
        paddingBottom: 2,
        paddingRight: 8
      }
    },
    MuiCardContent: {
      root: {
        paddingTop: 0,
        paddingLeft: 8,
        paddingBottom: 2,
        paddingRight: 8
      }
    },
    MuiCardActions: {
      root: {
        paddingTop: 0,
        paddingLeft: 8,
        paddingBottom: 2,
        paddingRight: 8
      }
    }
  }
}
