import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormProvider,
  UseFormReturn,
  useForm as useReactHookForm, UseFormProps,
  SubmitErrorHandler, SubmitHandler
} from 'react-hook-form'
import { createProxy, ObjPathProxy } from 'ts-object-path'
import * as z from 'zod'
import { DeepRequired } from '../../../utils/type-utils'
import { useTranslation } from 'react-i18next'
import { errorMap } from './util'

type Props<T> = {
  readonly f: UseFormReturn<T>
  readonly children: React.ReactNode
  readonly onSubmit: SubmitHandler<T>
  readonly onInvalid?: SubmitErrorHandler<T>
}

type UseFormOptions<T> = Omit<UseFormProps<T>, 'resolver'> & {
  readonly resolver: z.Schema<T>
}

export type UseFormResult<T> = UseFormReturn<T> & {
  readonly names: ObjPathProxy<DeepRequired<T>, DeepRequired<T>>
}

const Form = <T,>({ onSubmit, onInvalid, children, f }: Props<T>): JSX.Element => {
  return (
    <FormProvider {...f}>
      <form onSubmit={f.handleSubmit(onSubmit, onInvalid)}>{children}</form>
    </FormProvider>
  )
}

export const useForm = <T,>(opts: UseFormOptions<T>): UseFormResult<T> => {
  const { t } = useTranslation()
  const names = createProxy<DeepRequired<T>>()

  const f = useReactHookForm<T>({
    ...opts,
    resolver: zodResolver(opts.resolver, { errorMap: errorMap(t) })
  })

  return {
    ...f,
    names
  }
}

export default Form
