import * as React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core'

// There is any combination for props 'to', 'href', 'onClick' is possible (even no any is set), no need to restrict.
export type LinkProps = MuiLinkProps & Pick<RouterLinkProps, 'to'>

const Link: React.FC<LinkProps> = (props) => (
  <MuiLink component={RouterLink} {...props} />
)

export default Link
