import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { CheckboxProps } from '../Checkbox'
import { FormInputProps } from './types'
import { useFormInputProps } from './util'
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'

type FormCheckboxProps = FormInputProps<boolean> & Omit<CheckboxProps, 'name' | 'value' | 'onChange' | 'checked'>

const FormCheckbox: React.FC<FormCheckboxProps> = ({label, ...props}) => {
  const f = useFormContext()
  const inputProps = useFormInputProps(props)

  return (
    <FormControl error={inputProps.error}>
      <FormControlLabel
        label={label}
        control={<Controller
          name={inputProps.name}
          control={f.control}
          render={({ field: { value, ...field } }) => (
            <Checkbox {...props} {...field} checked={value as boolean} onChange={(e) => field.onChange(e.target.checked)} />
          )}
        />}
      />
      {inputProps.helperText && <FormHelperText error={inputProps.error}>{inputProps.helperText}</FormHelperText>}
    </FormControl>
  )
}

export default FormCheckbox
