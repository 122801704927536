import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { FormSelectField } from './Form'
import { useDryersQuery } from '../../api/dryers'
import { useTranslation } from 'react-i18next'
import { getDryerOptions } from '../dryer-utils'

type Props = {
  readonly field: string
  readonly withDefault?: boolean
}

const SelectDryerInput: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const fetch = useDryersQuery()

  const dryerOptions = (fetch.isSuccess ? getDryerOptions(fetch.data?.filter((d) => d.sensorDevices.length > 0)) : [])
  const defaultDryer = (props.withDefault === true && dryerOptions.length === 1) ? dryerOptions[0].value : undefined

  return (
    <React.Fragment>
    {fetch.isLoading && <CircularProgress />}
    {fetch.isSuccess && (
      <FormSelectField
        name={props.field}
        label={t('dialog.select-dryer.dryer')}
        defaultValue={defaultDryer}
        options={dryerOptions}
        placeholder={t('controls.select.default-placeholder')}
      />
    )}
  </React.Fragment>)
}

export default SelectDryerInput
