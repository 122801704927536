import { TFunction } from 'i18next'
import { MessageType } from '../../context/NotificationContext'

export type MenuLink = {
  readonly to: string
  readonly translation: (t: TFunction) => string
  readonly msgTypes: ReadonlyArray<MessageType>
}

export const menuLink = (to: string, translation: (t: TFunction) => string, msgTypes?: ReadonlyArray<MessageType>): MenuLink => ({ to, translation, msgTypes: msgTypes ?? [] })
