import Button from '@material-ui/core/Button'
import { styled as materialStyled } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import { withStyles } from '@material-ui/core'

const ExtendedButton = materialStyled(Button)(spacing)

export const ButtonStyled = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    textTransform: 'none'
  }
}))(ExtendedButton)
