import * as z from 'zod'
import {
  AlarmId,
  AlertType,
  DryerDescription,
  DryerId,
  Moisture,
  SensorDeviceLocalId,
  Temperature,
  TodoId
} from '../../../api/types'
import { PertenResultId } from '../../../api/sensors/perten/types'
import { DataSample } from '../../../api/dryers/measurements/types'
import { Username } from '../../../api/users/types'

export const MessageType = z.enum(['NewMoistureMeasurement', 'ConfirmedMoistureMeasurement', 'NewTodo', 'TodoReminder', 'NewAlarm', 'DisarmedAlarm', 'NewLedger', 'TodoDone', 'NewData'])
export type MessageType = z.infer<typeof MessageType>

export const SubscriptionStatus = z.enum(['SUCCESS', 'FAILURE'])

export const SubscriptionResponse = z.object({
  connectionId: z.string(),
  status: SubscriptionStatus
})

const BaseMessage = z.object({
  message: MessageType
})

export const NewLedgerMessage = BaseMessage.extend({
  message: z.literal(MessageType.enum.NewLedger),
  text: z.string(),
  author: Username,
  dryer: DryerDescription.nullish()
})
export type NewLedgerMessage = z.infer<typeof NewLedgerMessage>

export const NewMoistureMeasurement = BaseMessage.extend({
  message: z.literal(MessageType.enum.NewMoistureMeasurement),
  deviceId: SensorDeviceLocalId,
  address: z.string(),
  resultId: PertenResultId,
  grainProfile: z.string().nonempty(),
  moisture: Moisture,
  temperature: Temperature,
  natureKgPerL: z.number().positive(),
  originalTimestamp: z.string(), // local datetime
  receivedAt: z.string()      // instant
})
export type NewMoistureMeasurement = z.infer<typeof NewMoistureMeasurement>

export const ConfirmedMoistureMeasurement = BaseMessage.extend({
  message: z.literal(MessageType.enum.ConfirmedMoistureMeasurement),
  deviceId: SensorDeviceLocalId,
  resultId: PertenResultId,
  dryerId: DryerId,
  timestamp: z.string(), // instant
  confirmedAt: z.string(), // instant
  confirmedBy: Username
})
export type ConfirmedMoistureMeasurement = z.infer<typeof ConfirmedMoistureMeasurement>

export const NewTodo = BaseMessage.extend({
  message: z.literal(MessageType.enum.NewTodo),
  id: TodoId,
  task: z.string().nonempty(),
  createdAt: z.string(), // instant
  dryer: DryerDescription.nullish(),
  author: Username.nonempty()
})
export type NewTodo = z.infer<typeof NewTodo>

export const TodoReminder = BaseMessage.extend({
  message: z.literal(MessageType.enum.TodoReminder),
  id: TodoId,
  task: z.string().nonempty(),
  dryer: DryerDescription.nullish(),
  at: z.string().transform((v) => new Date(v)),
  assignee: Username.nullish()
})
export type TodoReminder = z.infer<typeof TodoReminder>

export const TodoDone = BaseMessage.extend({
  message: z.literal(MessageType.enum.TodoDone),
  id: TodoId,
  performer: Username
})
export type TodoDone = z.infer<typeof TodoDone>

export const NewAlarm = BaseMessage.extend({
  message: z.literal(MessageType.enum.NewAlarm),
  id: AlarmId,
  alert: AlertType,
  dryer: DryerDescription,
  startedAt: z.string()
})
export type NewAlarm = z.infer<typeof NewAlarm>

export const DisarmedAlarm = BaseMessage.extend({
  message: z.literal(MessageType.enum.DisarmedAlarm),
  id: AlarmId,
  alert: AlertType,
  dryer: DryerDescription
})
export type DisarmedAlarm = z.infer<typeof DisarmedAlarm>

export const NewDataMessage = BaseMessage.extend({
  message: z.literal(MessageType.enum.NewData),
  dryer: DryerDescription,
  data: DataSample
})

export const Message = z.union([NewTodo, NewMoistureMeasurement, TodoReminder, NewAlarm, DisarmedAlarm, NewLedgerMessage, TodoDone, NewDataMessage, ConfirmedMoistureMeasurement])
export type Message = z.infer<typeof Message>
