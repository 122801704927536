import { hasValue, OptionalString } from '@digital-magic/ts-common-utils/lib/type'

// Allow only string values in configuration to avoid any unsafe code execution
export const getWindowConfParam = (name: string): OptionalString => {
  if (typeof window === 'undefined' || !Object.prototype.hasOwnProperty.call(window, name)) {
    return undefined
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const prop: unknown = window[name]
  return hasValue(prop) && typeof prop === 'string' ? prop : undefined
}

export const buildAbsoluteFromRelativeUrl = (url: string): URL => new URL(window.location.origin + url)

export const buildAbsoluteUrl = (url: string): URL =>
  url.startsWith('/') ? buildAbsoluteFromRelativeUrl(url) : new URL(url)

export const buildAbsoluteWsUrl = (url: string): URL => {
  const u = buildAbsoluteUrl(url)
  switch (u.protocol) {
    case 'http:':
      // eslint-disable-next-line functional/immutable-data
      u.protocol = 'ws:'
      break
    case 'https:':
      // eslint-disable-next-line functional/immutable-data
      u.protocol = 'wss:'
      break
  }
  return u
}
