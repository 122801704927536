import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import reportWebVitals from './reportWebVitals'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import i18n from './i18n'
import App from './App'
import { QueryClientProvider, QueryErrorResetBoundary } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { baseUrl, snackbarAutoHideDurationInSeconds, snackbarMaxToasts } from './constants/configuration'
import { WorkModeContextProvider } from './components/context/WorkModeContext'
import { NotificationContextProvider } from './components/context/NotificationContext'
import { queryClient } from './api'
import { ErrorBoundary } from 'react-error-boundary'
import { errorRenderer, logError } from './components/pages/ErrorRenderer'
import { SwitchableThemeContextProvider } from './components/context/SwitchableThemeContext'
import { SessionPinContextProvider } from './components/context/SessionPinContext'
import { SnackbarProvider } from 'notistack'
import { millisecondsInSecond } from 'date-fns'

/* eslint-disable i18next/no-literal-string */
const LoadingComponent: React.FC = () => (
  <Box width={500} height={80} m="auto" mt={10} alignItems="center" justifyContent="center">
    <Box alignItems="center" justifyContent="center" textAlign="center">
      <CircularProgress variant="indeterminate" size={200} />
    </Box>
    <Box alignItems="center" justifyContent="center" textAlign="center">
      <Typography style={{ fontWeight: 600 }}>
        Loading...
      </Typography>
    </Box>
  </Box>
)

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <SwitchableThemeContextProvider>
          <SnackbarProvider
            dense
            preventDuplicate
            maxSnack={snackbarMaxToasts}
            autoHideDuration={snackbarAutoHideDurationInSeconds * millisecondsInSecond}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <Suspense fallback={<LoadingComponent />}>
              <SessionPinContextProvider>
                <BrowserRouter basename={baseUrl}>
                  <WorkModeContextProvider>
                    <NotificationContextProvider>
                      <QueryErrorResetBoundary>
                        {({ reset }) => (
                          <ErrorBoundary
                            fallbackRender={errorRenderer}
                            onError={logError}
                            onReset={reset}
                          >
                            <App />
                          </ErrorBoundary>
                        )}
                      </QueryErrorResetBoundary>
                    </NotificationContextProvider>
                  </WorkModeContextProvider>
                </BrowserRouter>
              </SessionPinContextProvider>
            </Suspense>
          </SnackbarProvider>
        </SwitchableThemeContextProvider>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
