import { apiBaseUrl } from '../../constants/configuration'
import { UseMutationOptions, UseMutationResult, UseQueryResult } from '../util/types'
import { CreateTodoRequest, Todo } from './types'
import { DryerId, TodoId } from '../types'
import { QueryKey } from 'react-query'
import {
  useApiMutation,
  useApiQuery,
  UseApiQueryOptions,
  useReceiveOnly,
  useSendOnly,
  useSendReceive
} from '../util/hooks'
import * as z from 'zod'

export const todoQueryKey: QueryKey = 'todos'
const todosBaseUrl = `${apiBaseUrl}/todos`

const TodoQueryResponse = z.array(Todo)
type TodoQueryResponse = z.infer<typeof TodoQueryResponse>

export const useTodoQuery = (dryerId?: DryerId, opts?: UseApiQueryOptions<TodoQueryResponse>): UseQueryResult<TodoQueryResponse> =>
  useApiQuery(
    [todoQueryKey, dryerId],
    useReceiveOnly('get', dryerId ? `${todosBaseUrl}?state=active&dryerId=${dryerId}` : `${todosBaseUrl}?state=active`, TodoQueryResponse),
    opts
  )

export const useCreateTodoMutation = (
  opts?: UseMutationOptions<TodoId, CreateTodoRequest>
): UseMutationResult<TodoId, CreateTodoRequest> =>
  useApiMutation(useSendReceive('post', todosBaseUrl, CreateTodoRequest, TodoId), {
    invalidateQueries: [todoQueryKey],
    ...opts
  })

export const useDisarmTodoMutation = (todoId: TodoId, opts?: UseMutationOptions<void, void>): UseMutationResult<void, void> =>
  useApiMutation(useSendOnly('delete', `${todosBaseUrl}/${todoId}/reminder`), {
    invalidateQueries: [todoQueryKey],
    ...opts
  })

export const useCompleteTodoMutation = (todoId: TodoId, opts?: UseMutationOptions<void, void>): UseMutationResult<void, void> =>
  useApiMutation(useSendOnly('delete', `${todosBaseUrl}/${todoId}`), {
    invalidateQueries: [todoQueryKey],
    ...opts
  })
