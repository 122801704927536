import * as z from 'zod'

export const zodIs = <T>(obj: unknown, schema: z.Schema<T>): obj is T => {
  return schema.safeParse(obj).success
}

export const mayBeType = <T>(obj: unknown, schema: z.Schema<T>): T | undefined => {
  if (zodIs(obj, schema)) return obj
  else return undefined
}
