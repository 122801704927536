import styled, { css } from 'styled-components'

export const HeaderStyled = styled.div`
  ${({ theme }) => css`
    .menu-icon-button {
      margin-right: ${theme.spacing(2)};
    }
    .menu-item {
      flex-grow: 1;
    }
  `}
`
