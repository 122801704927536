import * as z from 'zod'
import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios'
import { Primitive } from 'zod'
import { ZodError } from 'zod/lib/ZodError'
import { ApiErrorCode, ApiErrorObject, AppError } from './errors'
import { SessionPin } from '../auth/types'

export type UnknownError = AppError & {
  readonly name: 'UnknownError'
}
export const unknownError: (action: string) => AppError = (action) => ({
  name: 'UnknownError',
  message: `${action} failed`,
  messageKey: `client.errors.unknown.${action.replace(' ', '-')}`
})

export type NoResponseError = AppError & {
  readonly name: 'ServerUnavailable'
}
const serverDoesNotRespond: () => NoResponseError = () => ({
  name: 'ServerUnavailable',
  message: 'No network or the server does not respond',
  messageKey: 'client.errors.server-does-not-respond'
})

export type HttpError = AppError & {
  readonly name: 'HttpError'
  readonly httpStatus: number
}
const httpProtocolError: (status: number) => HttpError = (status) => ({
  name: 'HttpError',
  message: `HTTP error: ${status}`,
  httpStatus: status,
  messageKey: 'client.errors.http',
  messageOpts: { status }
})

export type InvalidRequest<T> = AppError & {
  readonly name: 'InvalidRequest'
  readonly error?: ZodError<T>
}
const invalidRequestError: <T>(error?: ZodError<T>) => InvalidRequest<T> = (error) => ({
  name: 'InvalidRequest',
  message: 'Invalid request',
  error,
  messageKey: 'client.errors.bad-request',
  messageOpts: {}
})

export type InvalidResponse<T> = AppError & {
  readonly name: 'IncompatibleApiResponse'
  readonly error: ZodError<T>
}
const invalidResponseError: <T>(error: ZodError<T>) => InvalidResponse<T> = (error) => ({
  name: 'IncompatibleApiResponse',
  message: 'Cannot parse server\'s response',
  error,
  messageKey: 'client.errors.bad-response',
  messageOpts: {}
})

export type ApiError = Error & {
  readonly name: 'ApiError'
  readonly errorCode: ApiErrorCode
}
const apiError: (code: ApiErrorCode, message: string) => ApiError = (code, message) => ({
  name: 'ApiError',
  message: message,
  errorCode: code,
  messageKey: 'client.errors.api',
  messageOpts: { code, message }
})

enum ContentType {
  JSON = 'application/json'
}

const buildHeaders = (pin: SessionPin | undefined, language: string | undefined): Record<string, Primitive> => {
  const stdHeaders = {
    'Accept': ContentType.JSON,
    'Content-Type': ContentType.JSON,
  }
  const withPin = pin ? { ...stdHeaders, 'X-SessionID' : pin } : stdHeaders
  return language ? { ...withPin, 'X-Language' : language } : withPin
}

const axiosErrorMapper: <T>(error: unknown) => Promise<AxiosResponse<T>> = (error) => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const errorDto = ApiErrorObject.safeParse(error.response.data)
      if (errorDto.success) {
        if (error.config.method && error.config.url) {
          console.log(`${error.config.method} ${error.config.url} failed by API: [${errorDto.data.extCode}] ${errorDto.data.debugMessage ?? ''}`)
        }
        return Promise.reject(apiError(errorDto.data.extCode, errorDto.data.message ?? errorDto.data.extCode))
      } else {
        if (error.config.method && error.config.url) {
          console.log(`${error.config.method} ${error.config.url} failed with HTTP status [${error.response.status}]`)
        }
        return Promise.reject(httpProtocolError(error.response.status))
      }
    } else if (error.request) {
      return Promise.reject(serverDoesNotRespond())
    } else {
      return Promise.reject(unknownError('network request'))
    }
  } else {
    return Promise.reject(unknownError('network request'))
  }
}

const buildNoSchemaError: <T>(method: Method, url: string) => Promise<T> = (method, url) => {
  console.log(`${method} ${url} trying perform request without validation`)
  return Promise.reject(invalidRequestError())
}

const buildErrorMapper: <R, T>(method: Method, url: string, error: ZodError<R>) => Promise<T> = (method, url, error) => {
  console.log(`${method} ${url} trying perform invalid request, found errors: ${JSON.stringify(error.issues)}`)
  return Promise.reject(invalidRequestError(error))
}

const parseErrorMapper: <T>(method: Method, url: string, error: ZodError<T>) => Promise<T> = (method, url, error) => {
  console.log(`${method} ${url} got invalid response, found errors: ${JSON.stringify(error.issues)}`)
  return Promise.reject(invalidResponseError(error))
}

type RequestConfig<I, S extends z.ZodType<I>> = Omit<AxiosRequestConfig, 'method' | 'url'> & {
  readonly language?: string
  readonly sessionPin?: SessionPin,
  readonly requestSchema?: S,
  readonly data?: I,
}

type ResponseConfig<V, T extends z.ZodType<V>> = Omit<AxiosRequestConfig, 'method' | 'url'> & {
  readonly language?: string
  readonly sessionPin?: SessionPin,
  readonly responseSchema: T
}

const verifyInputSchema: <I, S extends z.ZodType<I>>(
  method: Method, url: string, config?: RequestConfig<I, S>
) => Promise<void> = (method, url, config) => {
  if (config?.data) {
    if (config?.requestSchema) {
      const validated = config.requestSchema.safeParse(config.data)
      if (!validated.success) {
        return buildErrorMapper(method, url, validated.error)
      } else {
        return Promise.resolve()
      }
    } else {
      return buildNoSchemaError(method, url)
    }
  } else {
    return Promise.resolve()
  }
}

const doRequest: <I, S extends z.ZodType<I>>(method: Method, url: string, config?: RequestConfig<I, S>) => Promise<AxiosResponse<unknown>>
  = (method, url, config) =>
  (axios({
    ...config,
    method,
    url,
    withCredentials: true,
    validateStatus: (status) => status < 300,
    headers: buildHeaders(config?.sessionPin, config?.language),
  }).catch(axiosErrorMapper))

// In most cases, you do not want to use this method directly,
// better option is useSendOnly hook
export const send = async <I, S extends z.ZodType<I>>(
  method: Method,
  url: string,
  config?: RequestConfig<I, S>,
): Promise<void> => {
  await verifyInputSchema(method, url, config)
  await doRequest(method, url, config)
  return Promise.resolve()
}

// todo: currently its impossible to use type T, which contains .transform() in schema
// solution: https://github.com/colinhacks/zod#what-about-transforms
// In most cases, you do not want to use this method directly,
// better option is useReceiveOnly hook
export const read = async <V, T extends z.ZodType<V>>(
  method: Method,
  url: string,
  config: ResponseConfig<V, T>,
): Promise<V> => {
  const result = await doRequest(method, url, config)

  const validated = config.responseSchema.safeParse(result.data)
  if (validated.success) {
    return Promise.resolve(validated.data)
  } else {
    return parseErrorMapper(method, url, validated.error)
  }
}

// todo: currently its impossible to use type T, which contains .transform() in schema
// solution: https://github.com/colinhacks/zod#what-about-transforms (using z.input for all API types instead z.infer)
// In most cases, you do not want to use this method directly,
// better option is useSendReceive hook
export const sendAndRead = async <I, S extends z.ZodType<I>, V, T extends z.ZodType<V>>(
  method: Method,
  url: string,
  config: RequestConfig<I, S> & ResponseConfig<V, T>,
): Promise<V> => {
  await verifyInputSchema(method, url, config)

  const result = await doRequest(method, url, config)

  const validated = config.responseSchema.safeParse(result.data)
  if (validated.success) {
    return Promise.resolve(validated.data)
  } else {
    return parseErrorMapper(method, url, validated.error)
  }
}
