import { buildAbsoluteUrl, buildAbsoluteWsUrl, getWindowConfParam } from '../utils/config-utils'
import { flatMap } from '../utils/function-utils'

export const baseUrl: string = process.env.PUBLIC_URL ?? '/'
export const isDebugForms: boolean = flatMap(getWindowConfParam('REACT_APP_DEBUG_FORMS'), (v) => true.toString() === v.toLowerCase()) ?? false
export const isDebugTranslations: boolean = flatMap(getWindowConfParam('REACT_APP_DEBUG_I18N'), (v) => true.toString() === v.toLowerCase()) ?? false
export const apiBaseUrl: string = buildAbsoluteUrl(getWindowConfParam('REACT_APP_API_BASE_URL') ?? 'http://localhost:8080/api/v1').href
export const wsBaseUrl: string = buildAbsoluteWsUrl(getWindowConfParam('REACT_APP_WSS_BASE_URL') ?? 'ws://localhost:8080/api/v1/ws').href
export const appVersion: string = getWindowConfParam('REACT_APP_VERSION') ?? '0.0.0'

export const snackbarAutoHideDurationInSeconds = 7
export const snackbarMaxToasts = 4

export const LanguageConfiguration = {
  translationNamespace: 'translations',
  loadPath: `${baseUrl}/locales/{{lng}}/{{ns}}.json`,
  version: appVersion,
  availableLanguages: ['en', 'et', 'ru'],
  defaultLanguage: 'en',
  storageKey: 'grainstate_i18nextLng',
  cacheStorageKeyPrefix: 'grainstate_i18next_res_',
  cacheExpirationMs: process.env.NODE_ENV === 'production' ? 7 * 24 * 60 * 60 * 1000 : 60 * 1000
}

export const themeStorageKey = 'grainstate_materialTheme'
export const sessionStorageKey = 'grainstate_session'

console.log('Application configuration loaded:', {
  version: appVersion,
  languages: LanguageConfiguration,
  baseUrl: baseUrl,
  apiBaseUrl: apiBaseUrl,
  wsBaseUrl: wsBaseUrl,
  debugForms: isDebugForms,
  debugTranslations: isDebugTranslations
})
