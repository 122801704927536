import {
  format,
  formatDistance,
  intervalToDuration, millisecondsInHour, millisecondsInMinute,
  millisecondsInSecond, minutesInHour,
  secondsInHour,
  secondsInMinute
} from 'date-fns'
import { useCurrentLocale } from '../hooks/useCurrentLocale'

export const localDateTimeISOFormat = 'yyyy-MM-ddTHH:mm:ss'
export const dateTimeShortFormat = 'HH:mm:ss d MMM'
export const dateFormat = 'yyyy-MM-dd'
export const timeFormat = 'HH:mm:ss'
export const timeAxisFormat = 'MM-dd HH:mm'

type SafeDateType = Date | string | undefined

export const formatterDate =
  (fmt: string, locale?: Locale) =>
    (d: SafeDateType): string =>
      (d ? format(new Date(d), fmt, { locale }) : 'n/a')

export const formatterDistance =
  (locale?: Locale) =>
    (startDate: SafeDateType, endDate: SafeDateType): string =>
      (startDate && endDate ? formatDistance(new Date(endDate), new Date(startDate), { locale }) : 'n/a')

export function useDateFormatter(fmt: string): (d: SafeDateType) => string {
  const locale = useCurrentLocale()
  return formatterDate(fmt, locale)
}

export function useDistanceFormatter(): (startDate: SafeDateType, endDate: SafeDateType) => string {
  const locale = useCurrentLocale()
  return formatterDistance(locale)
}

export function duration2seconds(d: Duration): number {
  return (d.seconds ?? 0) + (d.minutes ?? 0) * secondsInMinute + (d.hours ?? 0) * secondsInHour
}

export function duration2minutes(d: Duration): number {
  return Math.round((d.seconds ?? 0) / secondsInMinute) + (d.minutes ?? 0) + (d.hours ?? 0) * minutesInHour
}

export function seconds2duration(seconds: number): Duration {
  return intervalToDuration({ start: 0, end: seconds * millisecondsInSecond })
}

export function minutes2duration(minutes: number): Duration {
  return intervalToDuration({ start: 0, end: minutes * millisecondsInMinute})
}

export function hours2duration(hours: number): Duration {
  return intervalToDuration({ start: 0, end: hours * millisecondsInHour})
}
