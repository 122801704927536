import * as React from 'react'
import * as z from 'zod'
import {
  useMutation, MutationFunction, UseMutationResult,
  useQuery, QueryFunction, QueryKey, UseQueryOptions, UseQueryResult, useQueryClient
} from 'react-query'
import { useTranslation } from 'react-i18next'
import { ApiError, read, send, sendAndRead } from './base-api'
import { Method } from 'axios'
import { ApiErrorCode, AppError } from './errors'
import { useCurrentLanguage } from '../../hooks/useCurrentLocale'
import { WorkModeContext } from '../../components/context/WorkModeContext'
import { SessionPinContext } from '../../components/context/SessionPinContext'
import { UseMutationOptions } from './types'
import { useSnackbar } from 'notistack'

export type UseApiQueryOptions<TQueryFnData, TData = TQueryFnData> = Omit<UseQueryOptions<TQueryFnData, AppError, TData>, 'queryKey' | 'queryFn'>
export type UseApiMutationOptions<TData, TVariables> = Omit<UseMutationOptions<TData , TVariables>, 'mutationFn'>
type LazyUrl<T> = ((x?: T) => string) | string

const buildUrl:<T> (url: LazyUrl<T>, data?: T) => string = (value, data) => value instanceof Function ? value(data) : value

// todo: combine these 3 methods into a single one
export const useSendOnly: <TVariables, TVariablesSchema extends z.ZodType<TVariables>>(method: Method, url: LazyUrl<TVariables>, requestSchema?: TVariablesSchema) => (data?: TVariables) => Promise<void> =
  (method, url, requestSchema) => {
    const language = useCurrentLanguage()
    const auth = React.useContext(SessionPinContext)

    return React.useCallback(
      (data) => {
        return send(method, buildUrl(url, data), {
          language,
          data,
          requestSchema: requestSchema,
          sessionPin: auth.usePin()
        })
      },
      [language, method, url, requestSchema, auth])
  }

export const useReceiveOnly: <TVariables, TData, TDataSchema extends z.ZodType<TData>>(method: Method, url: LazyUrl<TVariables>, responseSchema: TDataSchema) => (data?: TVariables) => Promise<TData> =
  (method, url, responseSchema) => {
    const language = useCurrentLanguage()
    const auth = React.useContext(SessionPinContext)

    return React.useCallback(
      () => read(method, buildUrl(url), {
        language,
        responseSchema,
        sessionPin: auth.usePin()
      }),
      [language, method, url, responseSchema, auth])
  }

export const useSendReceive: <TVariables, TVariablesSchema extends z.ZodType<TVariables>, TData, TDataSchema extends z.ZodType<TData>>(method: Method, url: LazyUrl<TVariables>, requestSchema: TVariablesSchema, responseSchema: TDataSchema) => (data: TVariables) => Promise<TData> =
  (method, url, requestSchema, responseSchema) => {
    const language = useCurrentLanguage()
    const auth = React.useContext(SessionPinContext)

    return React.useCallback(
      (data) => sendAndRead(method, buildUrl(url, data), {
        language,
        data,
        requestSchema,
        responseSchema,
        sessionPin: auth.usePin()
      }),
      [language, method, url, requestSchema, responseSchema, auth])
  }

export const isBadSession: (appError: AppError) => boolean = (appError) => {
  if (appError.name === 'ApiError') {
    const apiError = appError as unknown as ApiError
    switch (apiError.errorCode) {
      case ApiErrorCode.enum.IncorrectPassword:
      case ApiErrorCode.enum.SessionNotFound:
      case ApiErrorCode.enum.SessionInvalidOrExpired: {
        return true
      }
    }
  }
  return false
}

export const isPinRequired: (appError: AppError) => boolean = (appError) => {
  if (appError.name === 'ApiError') {
    const apiError = appError as unknown as ApiError
    switch (apiError.errorCode) {
      case ApiErrorCode.enum.SessionPinRequired: {
        return true
      }
    }
  }
  return false
}

export const useApiQuery: <TQueryFnData = unknown, TData = TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  options?: UseApiQueryOptions<TQueryFnData, TData>
) => UseQueryResult<TData, AppError> = (queryKey, queryFn, options) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const auth = React.useContext(WorkModeContext)

  const errorFunction: (userOnError: ((e: AppError) => void) | undefined) => (appError: AppError) => void = React.useCallback((userOnError) =>(appError) => {
    enqueueSnackbar(t(appError.messageKey, appError.messageOpts), {
      key: appError.messageKey,
      variant: 'error'
    })
    if (isBadSession(appError)) {
      auth.logout(undefined)
    }
    return userOnError ? userOnError(appError) : {}
  }, [enqueueSnackbar, t, auth])

  return useQuery(
    queryKey,
    queryFn,
    {
      ...options,
      onError: errorFunction(options?.onError)
    }
  )
}

export const useApiMutation: <TData, TVariables>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseApiMutationOptions<TData, TVariables>
) => UseMutationResult<TData, AppError, TVariables> = (mutationFn, options) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const auth = React.useContext(WorkModeContext)
  const pinCtx = React.useContext(SessionPinContext)
  const queryClient = useQueryClient()

  const handleErrorFinally: <TVariables> (userOnError: ((e: AppError, vars: TVariables, ctx: unknown) => void | Promise<unknown>) | undefined) =>
    (appError: AppError, vars: TVariables, ctx: unknown) => void | Promise<unknown> = React.useCallback((userOnError) => (appError, vars, ctx) => {
      enqueueSnackbar(t(appError.messageKey, appError.messageOpts), {
        key: appError.name,
        variant: 'error'
      })
      if (isBadSession(appError)) {
        auth.logout(undefined)
      }
      return userOnError ? userOnError(appError, vars, ctx) : Promise.resolve()
    }, [enqueueSnackbar, t, auth])

  const handleErrorWithRetry: (retry: () => Promise<unknown>) => (appError: AppError) => Promise<unknown> =
    React.useCallback((retry) => (appError) => {
      if (isPinRequired(appError)) {
        return pinCtx.askPin().then((pin) => {
          if (pin)
            return retry().catch((error) => {
              handleErrorFinally(error)
            })
          else
            return Promise.resolve()
        })
      } else if (isBadSession(appError)) {
        enqueueSnackbar(t(appError.messageKey, appError.messageOpts), {
          key: appError.name,
          variant: 'error'
        })
        auth.logout(undefined)
        return Promise.resolve()
      } else {
        enqueueSnackbar(t(appError.messageKey, appError.messageOpts), {
          key: appError.name,
          variant: 'error'
        })
        return Promise.resolve()
      }
    }, [enqueueSnackbar, handleErrorFinally, t, auth, pinCtx])

  const mutation = useMutation(
    mutationFn,
    {
      ...options,
      useErrorBoundary: (error) => !(isBadSession(error) || isPinRequired(error)),
      onSuccess: async (response, data, ctx) => {
        if (options?.invalidateQueries) {
          const results = options.invalidateQueries.map((q) => queryClient.invalidateQueries(q))
          await Promise.allSettled(results)
        }
        if (options?.onSuccess) {
          return options.onSuccess(response, data, ctx)
        }
        return
      }
    }
  )

  return {
    ...mutation,
    mutate: (variables, opts) =>
      mutation.mutate(variables, {
        ...opts,
        onError: handleErrorWithRetry(() => mutation.mutateAsync(variables, {
          ...opts,
          onError: handleErrorFinally(opts?.onError),
          onSettled: (data, err, vars, ctx) => opts?.onSettled ? opts.onSettled(data, err, vars, ctx) : Promise.resolve()
        })),
        onSettled: (data, err, vars, ctx) => {
          if (err) {
            return
          } else {
            return opts?.onSettled ? opts.onSettled(data, err, vars, ctx) : Promise.resolve()
          }
        }
      })
  }
}
