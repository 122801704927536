import * as z from 'zod'
import { AlertType, DryerId, DryerDescription, GrainType, SettingKey } from '../types'
import { GrainSample } from '../dryers/measurements/types'

export const JournalFacility = z.enum(['Alarms', 'Todos', 'Ledger', 'Sensors', 'Session'])
export type JournalFacility = z.infer<typeof JournalFacility>

export const JournalRecordType = z.enum([
  'AlarmFired',
  'AlarmAssigned',
  'AlarmCleared',
  'SettingChanged',
  'ManualMoisture',
  'ForeignMoisture',
  'TodoCreated',
  'TodoCompleted',
  'Ledger',
  'UserSessionStarted',
  'UserSessionFinished'
])

const JournalEntryBase = z.object({
  kind: JournalRecordType,
  timestamp: z.string(),
  facility: JournalFacility,
  source: z.string(),
  dryer: DryerDescription.nullish()
})

export const AlarmFired = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.AlarmFired),
  facility: z.literal(JournalFacility.enum.Alarms),
  alertType: AlertType,
  dryer: DryerDescription
})

export const AlarmAssigned = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.AlarmAssigned),
  facility: z.literal(JournalFacility.enum.Alarms),
  alertType: AlertType,
  dryer: DryerDescription
})

export const AlarmCleared = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.AlarmCleared),
  facility: z.literal(JournalFacility.enum.Alarms),
  alertType: AlertType,
  dryer: DryerDescription
})

export const SettingChangedRecord = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.SettingChanged),
  facility: z.literal(JournalFacility.enum.Sensors),
  setting: SettingKey,
  oldValue: z.string(),
  newValue: z.string(),
  dryer: DryerDescription
})

export const ManualMeasurementRecord = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.ManualMoisture),
  facility: z.literal(JournalFacility.enum.Sensors),
  grainType: GrainType,
  dry: z.boolean(),
  data: GrainSample,
  dryer: DryerDescription
})

export const ForeignMeasurementRecord = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.ForeignMoisture),
  facility: z.literal(JournalFacility.enum.Sensors),
  grainType: GrainType,
  dry: z.boolean(),
  data: GrainSample,
  dryer: DryerDescription
})

export const TodoCreatedRecord = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.TodoCreated),
  facility: z.literal(JournalFacility.enum.Todos),
  task: z.string(),
  remindAt: z.string().nullish(), // date
})

export const TodoCompletedRecord = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.TodoCompleted),
  facility: z.literal(JournalFacility.enum.Todos),
  task: z.string(),
  author: z.string()
})

export const LedgerRecord = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.Ledger),
  facility: z.literal(JournalFacility.enum.Ledger),
  message: z.string()
})

export const WorkShiftStarted = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.UserSessionStarted),
  facility: z.literal(JournalFacility.enum.Session),
  address: z.string()
})

export const WorkShiftFinished = JournalEntryBase.extend({
  kind: z.literal(JournalRecordType.enum.UserSessionFinished),
  facility: z.literal(JournalFacility.enum.Session)
})

export const JournalEntry = z.union([
  AlarmFired,
  AlarmAssigned,
  AlarmCleared,
  SettingChangedRecord,
  ManualMeasurementRecord,
  ForeignMeasurementRecord,
  TodoCreatedRecord,
  TodoCompletedRecord,
  LedgerRecord,
  WorkShiftStarted,
  WorkShiftFinished
])
export type JournalEntry = z.infer<typeof JournalEntry>

export const JournalPage = z.object({
  items: z.array(JournalEntry),
  hasMore: z.boolean()
})
export type JournalPage = z.infer<typeof JournalPage>

export const CreateLedgerRecordRequest = z.object({
  dryerId: DryerId.optional(),
  message: z.string().nonempty()
})

export type CreateLedgerRecordRequest = z.infer<typeof CreateLedgerRecordRequest>
