import * as React from 'react'
import { CustomTheme, ThemeType } from '../../styles/theme/types'
import { useValidatedLocalStorage } from '../../hooks/StorageHooks'
import { theme } from '../../styles/theme'
import { MuiThemeProvider } from '@material-ui/core'
import { ThemeProvider } from 'styled-components'
import { themeStorageKey } from '../../constants/configuration'

export type SwitchableThemeContextValue = {
  readonly currentTheme: CustomTheme
  readonly setCurrentTheme: (type: ThemeType) => void
}

export const SwitchableThemeContext = React.createContext<SwitchableThemeContextValue>({} as SwitchableThemeContextValue)

export const SwitchableThemeContextProvider: React.FC = ({ children }) => {
  const [currentThemeType, setCurrentThemeType] = useValidatedLocalStorage(themeStorageKey, 'light', ThemeType)
  const currentTheme = theme(currentThemeType)

  return (
    <MuiThemeProvider theme={currentTheme}>
      <ThemeProvider theme={currentTheme}>
        <SwitchableThemeContext.Provider
          value={{
            currentTheme,
            setCurrentTheme: setCurrentThemeType
          }}
        >
          {children}
        </SwitchableThemeContext.Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

