import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextField, { TextFieldProps } from '../TextField'
import { FormInputProps } from './types'
import { useFormInputProps } from './util'
import { parseISO } from 'date-fns'
import { OptionalString, OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { formatterDate } from '../../../utils/date-utils'

type FormDateFieldProps = FormInputProps<Date> & Omit<TextFieldProps, 'name' | 'value' | 'onChange'>

type ValueType = OptionalType<Date>

const dateFormat = 'yyyy-MM-dd\'T\'HH:mm:ss' 
const input = (value: ValueType): OptionalString => (value ? formatterDate(dateFormat)(value) : undefined)
const output = (value: OptionalString): ValueType => (value ? parseISO(value) : undefined)

const FormDateField: React.FC<FormDateFieldProps> = (props) => {
  const f = useFormContext()
  const inputProps = useFormInputProps<Date>(props)

  return (
    <Controller
      control={f.control}
      name={inputProps.name}
      defaultValue={undefined}
      render={({ field }) => (
        <TextField
          type="datetime-local"
          InputLabelProps={{ shrink: true }}
          {...props}
          {...inputProps}
          {...field}
          inputProps={{ ...props.inputProps }}
          value={input(field.value)}
          onChange={(e) => field.onChange(output(e.target.value))}
        />
      )}
    />
  )
}

export default FormDateField
