import {
  CoolingMode,
  DryerObject,
  DryerSetupInfo,
  DryingMode, DryingZoneCondition, GrainCondition,
  RecyclingMode, UnknownMode
} from '../api/dryers/types'
import { mayBeType } from '../utils/zod-utils'
import { flatMap, optional } from '../utils/function-utils'
import { SelectValueType, ValueLabel } from './controls/SelectField'
import { hasValue, OptionalType } from '@digital-magic/ts-common-utils/lib/type'

export function getDryingZoneInfo(dryer: DryerObject): DryingZoneCondition | undefined {
  return flatMap(mayBeType(dryer.mode, DryingMode), (m) => optional(m.drying)) ??
    flatMap(mayBeType(dryer.mode, RecyclingMode), (m) => optional(m.drying))
}

export function getDrySeeds(dryer: DryerObject): GrainCondition | undefined {
  return flatMap(mayBeType(dryer.mode, UnknownMode), (m) => optional(m.drySeeds)) ??
    flatMap(mayBeType(dryer.mode, DryingMode), (m) => optional(m.drySeeds)) ??
    flatMap(mayBeType(dryer.mode, CoolingMode), (m) => optional(m.drySeeds)) ??
    flatMap(mayBeType(dryer.mode, RecyclingMode), (m) => optional(m.drySeeds))
}

export function getWetSeeds(dryer: DryerObject): GrainCondition | undefined {
  return flatMap(mayBeType(dryer.mode, UnknownMode), (m) => optional(m.wetSeeds)) ??
    flatMap(mayBeType(dryer.mode, DryingMode), (m) => optional(m.wetSeeds))
}

export function getDryerSetupInfo(dryer: DryerObject): DryerSetupInfo | undefined {
  return mayBeType(dryer.mode, DryerSetupInfo)
}

export function getDryerOptions(dryers: OptionalType<ReadonlyArray<DryerObject>>): ReadonlyArray<ValueLabel<SelectValueType>> {
  return hasValue(dryers)
    ? dryers.map((d) => ({
      label: d.name,
      value: d.id
    }))
    : []
}
