import * as React from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { wsBaseUrl } from '../../../constants/configuration'
import { SessionType, WorkModeContext } from '../WorkModeContext'
import { Message, MessageType, SubscriptionResponse, SubscriptionStatus } from './types'
import { v4 as uuidv4 } from 'uuid'

type NotificationContextValue = {
  readonly active: boolean
  readonly lastMessage: MboxMessage | undefined
  readonly messages: (msgType: MessageType) => number
  readonly seenMessage: (id: string) => void
  readonly seenMessages: (msgType: MessageType) => void
}

type MboxMessage = Message & {
  readonly luid: string
}

export const NotificationContext = React.createContext({} as NotificationContextValue)

export const NotificationContextProvider: React.FC = ({ children }) => {
  const auth = React.useContext(WorkModeContext)
  const [connectionId, setConnectionId] = React.useState<string | undefined>(undefined)
  // not safe for multi-subscribers
  const [mbox, setMbox] = React.useState<ReadonlyArray<MboxMessage>>([])
  const url: () => Promise<string> = React.useCallback(() => {
    return new Promise((resolve) => {
      if (auth.sessionType === SessionType.enum.workshift) resolve(wsBaseUrl)
    })
  }, [auth])

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { readyState } = useWebSocket(url, {
    onMessage: (event) => {
      if (connectionId !== undefined) {
        const parsed = Message.safeParse(JSON.parse(event.data))
        if (parsed.success) {
          setMbox(prev => prev.concat({ ...parsed.data, luid: uuidv4().toString() }))
        } else {
          console.warn(`got unexpected message in ${connectionId}: `, event.data)
        }
      } else {
        const handshake = SubscriptionResponse.safeParse(JSON.parse(event.data))
        if (handshake.success && handshake.data.status === SubscriptionStatus.enum.SUCCESS) {
          console.log(`ws subscribed as ${handshake.data.connectionId}`)
          setConnectionId(handshake.data.connectionId)
        } else {
          console.warn('protocol error, got message while connection is not established', event)
        }
      }
    },
    onClose: (event) => {
      console.log(`ws ${connectionId ?? 'n/a'} connection has been closed`, event)
      setConnectionId(undefined)
    },
    onError: (event) => {
      console.warn(`ws ${connectionId ?? 'n/a'} connection error: `, event)
      setConnectionId(undefined)
    },
    shouldReconnect: () => true, // always try to reconnect
    share: true
  })

  const lastMessage: MboxMessage | undefined = React.useMemo(() => {
    return mbox.length > 0 ? mbox[0] : undefined
  }, [mbox])

  const messages: (msgType: MessageType) => number = React.useCallback((msgType) => {
    return mbox.filter(m => m.message === msgType).length
  }, [mbox])

  const seenMessage: (id: string) => void = React.useCallback((id) => {
    setMbox(prev => prev.filter(m => m.luid !== id))
  }, [setMbox])

  const seenMessages: (msgType: MessageType) => void = React.useCallback((msgType) => {
    setMbox(prev => prev.filter(m => m.message !== msgType))
  }, [setMbox])

  return (
    <NotificationContext.Provider
      value={{
        active: readyState === ReadyState.OPEN,
        lastMessage,
        messages,
        seenMessage,
        seenMessages
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
