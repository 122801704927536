import * as React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { GlobalStyles } from '../../../styles/GlobalStyles'
import { OperatorHeader, TechnicianHeader } from '../Header'
import { MainLayoutStyled } from './style'
import NotificationCenter from '../NotificationCenter'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { SessionType } from '../../context/WorkModeContext'

type Props = {
  // If role is undefined - user is not logged in or role is not supported by this WEB application.
  readonly workMode: SessionType,
  readonly children: React.ReactNode
}

const getRoleHeader = (workMode: SessionType): OptionalType<JSX.Element> => {
  switch (workMode) {
    case SessionType.enum.workshift:
      return <OperatorHeader />
    case SessionType.enum.technician:
      return <TechnicianHeader />
    default:
      return undefined
  }
}

const MainLayout: React.FC<Props> = ({ workMode, children }) => {
  return (
    <MainLayoutStyled>
      <CssBaseline />
      <GlobalStyles />
      {getRoleHeader(workMode)}
      <main>
        {/* TODO: Clarify if other user roles should also use Notifications */}
        {workMode === SessionType.enum.workshift && <NotificationCenter />}
        {children}
      </main>
    </MainLayoutStyled>
  )
}

export default MainLayout
