import * as React from 'react'
import { SessionPin } from '../../api/auth/types'
import { PinInputDialog } from '../dialogs/SessionPin/PinInputDialog'
import { OptionalType } from '@digital-magic/ts-common-utils/lib/type'
import { addMinutes, isBefore } from 'date-fns'

export type SessionPinContextValue = {
  readonly askPin: () => Promise<SessionPin | undefined>
  readonly usePin: () => SessionPin | undefined
  readonly setPin: (pin: SessionPin | undefined) => void
}

export const SessionPinContext = React.createContext({} as SessionPinContextValue)

type PromiseExecutor = (p: string | Promise<OptionalType<string>> | undefined) => void

type PinState = {
  readonly pin: SessionPin
  readonly validUntil: Date
}

const getState: (pin: SessionPin, until: Date) => PinState = (pin, until) => ({ pin, validUntil: until })

export const SessionPinContextProvider: React.FC = ({ children }) => {
  const [currentPin, setCurrentPin] = React.useState<OptionalType<PinState>>(undefined)
  const [asker, setAsker] = React.useState<OptionalType<PromiseExecutor>>(undefined)

  const askPin: () => Promise<SessionPin | undefined> = () => {
    setCurrentPin(undefined)
    return new Promise<SessionPin | undefined>((resolve) => {
      setAsker((prevState: OptionalType<PromiseExecutor>) => {
        if (prevState !== undefined) prevState(undefined) // resolve to undefined previous promise
        return resolve
      })
    })
  }

  const usePin = React.useCallback(() => {
    const pin = currentPin?.pin
    if (currentPin && isBefore(currentPin.validUntil, new Date())) {
      setCurrentPin(undefined)
      return undefined
    } else {
      return pin
    }
  }, [currentPin, setCurrentPin])

  const setPin = React.useCallback((pin: SessionPin | undefined) => {
    if (pin)
      setCurrentPin(getState(pin, addMinutes(new Date(), 1)))
    else
      setCurrentPin(undefined)
  }, [setCurrentPin])

  return (
    <SessionPinContext.Provider
      value={{
        askPin,
        usePin,
        setPin
      }}
    >
      <PinInputDialog open={asker !== undefined} onComplete={(pin) => {
        setPin(pin)
        if (asker) {
          asker(pin)
          setAsker(undefined)
        }
      }} />
      {children}
    </SessionPinContext.Provider>
  )
}
