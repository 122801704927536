import * as z from 'zod'
import { DryerDescription, TodoId } from '../types'
import { Username } from '../users/types'

export const TodoState = z.enum(['ACTIVE', 'DONE'])
export type TodoState = z.infer<typeof TodoState>

export const Todo = z.object({
  id: TodoId,
  dryer: DryerDescription.nullish(),
  state: TodoState,
  task: z.string(),
  alertAt: z.string().nullish(), // date
  author: Username,
  createdAt: z.string(), // date
  completedAt: z.string().nullish(), // date
  completedBy: Username.nullish(),
  assignee: Username.nullish()
})
export type Todo = z.infer<typeof Todo>

export const CreateTodoRequest = z.object({
  task: z.string().nonempty(),
  alertAt: z.date().optional(),
  dryerId: z.string().uuid().optional(),
  assignee: Username.optional()
})
export type CreateTodoRequest = z.infer<typeof CreateTodoRequest>
