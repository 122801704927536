import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { FormInputProps } from './types'
import { useFormInputProps } from './util'
import TextField, { TextFieldProps } from '../TextField'
import { hasValue, isNotEmptyString } from '@digital-magic/ts-common-utils/lib/type'

type FormNumberFieldProps = FormInputProps<number> & Omit<TextFieldProps, 'name' | 'value' | 'onChange'>

const FormNumberField: React.FC<FormNumberFieldProps> = (props) => {
  const f = useFormContext()
  const inputProps = useFormInputProps<number>(props)

  const input = (value: number | undefined): string => (hasValue(value) ? value.toString() : '')
  const output = (value: string | null): number | undefined =>
    hasValue(value) && isNotEmptyString(value) ? parseFloat(value) : undefined

  return (
    <Controller
      control={f.control}
      name={inputProps.name}
      render={({ field }) => (
        <TextField
          type="number"
          {...props}
          {...inputProps}
          {...field}
          value={input(field.value)}
          onChange={(e) => field.onChange(output(e.target.value))}
        />
      )}
    />
  )
}

export default FormNumberField
