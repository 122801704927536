import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SessionType, WorkModeContext } from '../../context/WorkModeContext'
import { MenuLink } from './types'
import {
  AppBar, Badge, Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon, ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography
} from '@material-ui/core'
import { HeaderStyled } from './styles'
import PersonIcon from '@material-ui/icons/Person'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import BuildIcon from '@material-ui/icons/Build'
import Link from '../../controls/Link'
import LanguageSelect from '../../controls/LanguageSelect'
import { hasValue } from '@digital-magic/ts-common-utils/lib/type'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../constants/routes'
import { useLogoutUser } from '../../../api/auth'
import { NotificationContext } from '../../context/NotificationContext'
import { getWindowConfParam } from '../../../utils/config-utils'

type Props = {
  readonly menuItems: ReadonlyArray<MenuLink>
  readonly children?: React.ReactNode
}

const HeaderMenu: React.FC<Props> = ({ menuItems, children }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const auth = React.useContext(WorkModeContext)
  const { messages } = React.useContext(NotificationContext)
  const [ sidebarOpen, setSidebarOpen ] = React.useState(false)
  const logoutUser = useLogoutUser()

  const onLogin: React.MouseEventHandler = (e) => {
    e.preventDefault()
    history.push(routes.Login, { loginOnly: true })
    setSidebarOpen(false)
  }

  const onLogout: React.MouseEventHandler = (e) => {
    e.preventDefault()
    logoutUser.mutate(undefined,{
      onSuccess: (data) => {
        auth.logout(data.workShiftActive)
      }
    })

    setSidebarOpen(false)
  }

  const onOpenDrawer: React.MouseEventHandler = (e) => {
    e.preventDefault()
    setSidebarOpen(true)
  }

  const showBadge: (item: MenuLink) => number = (item) =>
    item.msgTypes.map(messages).reduce((p,c) => p + c, 0)

  return (
    <HeaderStyled>
      <AppBar position="fixed" color="default" elevation={0} style={{ height: 60 }}>
        <Toolbar variant="regular" className={'bar'}>
          <SwipeableDrawer
            anchor='right'
            open={sidebarOpen}
            onOpen={() => setSidebarOpen(true)}
            onClose={() => setSidebarOpen(false)}
          >
            <List>
              <Divider />
              <Typography color='primary' variant='h6' style={{ marginLeft: '8px' }}>
                {`${t('app.version')}: ${getWindowConfParam('REACT_APP_VERSION') ?? 'n/a'}`}
              </Typography>
              <Typography color='primary' variant='h6' style={{ marginLeft: '8px' }}>
                {`${t('app.build')}: ${getWindowConfParam('REACT_APP_BUILD') ?? 'n/a'}`}
              </Typography>
              <Divider />
              {auth.sessionType !== SessionType.enum.technician &&
                <React.Fragment>
                  <ListItem>
                    <ListItemText primary={t('header.sidebar.divider-work-shift')} />
                  </ListItem>
                  <Divider />
                  <ListItem button key={'start-work-shift'} onClick={onLogin}>
                    <ListItemIcon><PersonAddIcon /></ListItemIcon>
                    <ListItemText primary={t('header.sidebar.start-work-shift')} />
                  </ListItem>
                  <ListItem button key={'finish-work-shift'} onClick={onLogout}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary={t('header.sidebar.finish-work-shift')} />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary={t('header.sidebar.divider-tech')} />
                  </ListItem>
                  <Divider />
                  <ListItem button key={'switch-to-tech'} onClick={onLogin}>
                    <ListItemIcon><BuildIcon /></ListItemIcon>
                    <ListItemText primary={t('header.sidebar.switch-to-tech')} />
                  </ListItem>
                </React.Fragment>
              }
              <ListItem button key={'logout'} onClick={onLogout}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={t('header.sidebar.logout')} />
              </ListItem>
            </List>
          </SwipeableDrawer>
          {menuItems.map((v, i) => (
            <Link to={v.to} color="inherit" className="menu-item" key={i}>
              <Badge badgeContent={showBadge(v)} max={9} color="secondary"   >
                <Typography variant="h6" color="inherit" >
                  {v.translation(t)}
                </Typography>
              </Badge>
            </Link>
          ))}
          <LanguageSelect />
          {hasValue(children) && children}
          <IconButton edge="start" color="inherit" aria-label="menu" className="menu-icon-button" onClick={onOpenDrawer}>
            <PersonIcon fontSize={'large'} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </HeaderStyled>
  )
}

export default HeaderMenu
