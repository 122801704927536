import { apiBaseUrl } from '../../constants/configuration'
import {
  ActivationRequest,
  DryerObject,
  DryerParametersResponse,
  DryerQueryResponse,
  Settings,
  SettingsMap
} from './types'
import { UseMutationOptions, UseMutationResult, UseQueryResult } from '../util/types'
import { QueryKey } from 'react-query'
import { DryerId } from '../types'
import { useApiMutation, useApiQuery, useReceiveOnly, useSendOnly } from '../util/hooks'
import { DeviceListResponse } from '../sensors/types'

export const allDryersQueryKey: QueryKey = 'dryers'
export const dryerKey: (dryerId: DryerId) => QueryKey =
  (dryerId) => [ allDryersQueryKey, dryerId ]
const dryerSettingsKey: (dryerId: DryerId) => QueryKey =
  (dryerId) => [ ...dryerKey(dryerId), 'settings' ]
export const dryerDevicesKey: (dryerId: DryerId) => QueryKey =
  (dryerId) => [ ...dryerKey(dryerId), 'devices' ]


const dryersBaseUrl = `${apiBaseUrl}/dryers`
export const dryerUrl: (id: DryerId) => string = (id) => `${dryersBaseUrl}/${id}`
const dryerPlcBindingUrl: (d: DryerId) => string = (dryerId) => `${dryerUrl(dryerId)}/deployment`
const baseSettingsUrl: (dryerId: DryerId) => string = (dryerId) => `${dryerUrl(dryerId)}/settings`

export const useDryersQuery = (): UseQueryResult<DryerQueryResponse> =>
  useApiQuery(allDryersQueryKey, useReceiveOnly('get', dryersBaseUrl, DryerQueryResponse))

export const useDryerQuery = (dryerId: DryerId): UseQueryResult<DryerObject> =>
  useApiQuery(dryerKey(dryerId), useReceiveOnly('get',dryerUrl(dryerId), DryerObject))

export const useDryerDevicesQuery = (dryerId: DryerId): UseQueryResult<DeviceListResponse> =>
  useApiQuery(dryerDevicesKey(dryerId), useReceiveOnly('get', `${dryerUrl(dryerId)}/devices`, DeviceListResponse))

export const useActivateDryer = (dryerId: DryerId, opts?: UseMutationOptions<void, ActivationRequest>): UseMutationResult<void, ActivationRequest> =>
  useApiMutation(useSendOnly('post', dryerPlcBindingUrl(dryerId), ActivationRequest), {
    invalidateQueries: [allDryersQueryKey, dryerKey(dryerId), dryerDevicesKey(dryerId)],
    ...opts
  })

export const useDeactivateDryer = (dryerId: DryerId, opts?: UseMutationOptions<void, void>): UseMutationResult<void, void> =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useApiMutation(useSendOnly('delete', dryerPlcBindingUrl(dryerId)), {
    invalidateQueries: [allDryersQueryKey, dryerKey(dryerId), dryerDevicesKey(dryerId)],
    ...opts
  })

export const useDryerParametersQuery = (): UseQueryResult<DryerParametersResponse> =>
  useApiQuery('dryer-parameters', useReceiveOnly('get', `${dryersBaseUrl}/parameters`, DryerParametersResponse))

export const useCurrentSettingsQuery: (dryerId: DryerId) => UseQueryResult<SettingsMap> = (dryerId) =>
  useApiQuery(dryerSettingsKey(dryerId), useReceiveOnly('get', baseSettingsUrl(dryerId), SettingsMap))

export const useSettingsMutation = (dryerId: DryerId, opts?: UseMutationOptions<void, Settings>): UseMutationResult<void, Settings> =>
  useApiMutation(useSendOnly('put', baseSettingsUrl(dryerId), Settings),{
    invalidateQueries: [dryerKey(dryerId), dryerSettingsKey(dryerId)],
    ...opts
  })
