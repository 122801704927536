import * as z from 'zod'
import { apiBaseUrl } from '../../constants/configuration'
import { UseMutationOptions, UseMutationResult, UseQueryResult } from '../util/types'
import { AlarmId, DryerId } from '../types'
import { PlcAlarm } from './types'
import { QueryKey } from 'react-query'
import { hasValue } from '@digital-magic/ts-common-utils/lib/type'
import { queryDateTime } from '../index'
import { useApiMutation, useApiQuery, UseApiQueryOptions, useReceiveOnly, useSendOnly } from '../util/hooks'

export const getAlarmsQueryKey: QueryKey = 'alarms'
const alarmsBaseUrl = `${apiBaseUrl}/alarms`

const getAlarmsUrl = (historic: boolean, dryerId?: DryerId, from?: Date, to?: Date): string => {
  const url = new URL(alarmsBaseUrl)
  if (hasValue(dryerId)) {
    url.searchParams.append('dryerId', dryerId)
  }
  if (historic) {
    url.searchParams.append('includeHistory', 'true')
  }
  if (hasValue(from)) {
    url.searchParams.append('from', queryDateTime(from))
  }
  if (hasValue(to)) {
    url.searchParams.append('to', queryDateTime(to))
  }
  return url.href
}

const AlarmQueryResponse = z.array(PlcAlarm)
type AlarmQueryResponse = z.infer<typeof AlarmQueryResponse>

export const useAlarmsQuery = (withHistory: boolean, dryerId?: DryerId, from?: Date, to?: Date, opts?: UseApiQueryOptions<AlarmQueryResponse>): UseQueryResult<AlarmQueryResponse> => {
  return useApiQuery(
    [getAlarmsQueryKey, { withHistory, dryerId, from, to }],
    useReceiveOnly('get', getAlarmsUrl(withHistory, dryerId, from, to), AlarmQueryResponse),
    opts
  )
}

export const useAckAlarmMutation = (alarmId: AlarmId, opts?: UseMutationOptions<void, void>): UseMutationResult<void, void> => {
  return useApiMutation(useSendOnly('put', `${alarmsBaseUrl}/${alarmId}`), {
    invalidateQueries: [getAlarmsQueryKey],
  ...opts
  })
}
