import * as z from 'zod'
import { AlarmId, AlertType, DryerId, DryerDescription, SettingKey, UserId } from '../types'

export const PlcAlarmState = z.enum(['ACTIVE', 'ACTIVE_ACKNOWLEDGED', 'FINISHED'])

const AlarmRecommendation = z.object({
  sensor: SettingKey,
  recommendation: z.string(),
  dryerId: DryerId.optional()
})

const AlarmAssignment = z.object({
  assigneeId: UserId,
  assigneeName: z.string(),
  assignedAt: z.string() // instant
})

export const PlcAlarm = z.object({
  id: AlarmId,
  dryer: DryerDescription,
  alertType: AlertType,
  status: PlcAlarmState,
  timeStarted: z.string(), // instant
  timeFinished: z.string().nullish(), // instant
  assignment: AlarmAssignment.nullish(),
  recommendation: AlarmRecommendation.nullish()
})
export type PlcAlarm = z.infer<typeof PlcAlarm>
