import { MenuItem, TextFieldProps } from '@material-ui/core'
import React from 'react'
import { SelectInputStyled } from './style'

export type SelectValueType = string | number

export type ValueLabel<T extends SelectValueType> = {
  readonly label: React.ReactNode
  readonly value: T | undefined
  readonly disabled?: boolean
}

export type SelectFieldProps<T extends SelectValueType> = TextFieldProps & {
  readonly options: ReadonlyArray<ValueLabel<T>>
}

const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps<SelectValueType>>(
  ({ options, ...props }, ref) => {
    return (
      <SelectInputStyled {...props} select ref={ref}>
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value} disabled={o.disabled ?? false}>
            {o.label}
          </MenuItem>
        ))}
      </SelectInputStyled>
    )
  }
)

export default SelectField
